// IMPORT DEPENDENCIES
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { setOpenUserDrawer, setOpenProjectDrawer, setOpenInvestDrawer, setOpenPaymentDrawer, setOpenOrganisationDrawer, setOpenSettingDrawer } from '../../store/actions/layouts';

// IMPORT IMAGES
import Logo from '../../assets/images/logo.png';

// IMPORT CSS
import '../../assets/css/mainstyle.css';

const Navbar = ({ onChangeDrawer, isDrawerOpen }) => {
    const history = useHistory();
    const location = useLocation();
    const { pathname } = location;

    const dispatch = useDispatch();
    const [drawer, setDrawer] = useState(false)
    const [user, setUser] = useState(false)
    const [projet, setProjet] = useState(false)
    const [investissement, setInvestissement] = useState(false)
    const [paiement, setPaiement] = useState(false)
    const [organisation, setOrganisation] = useState(false)
    const [settings, setSettings] = useState(false)


    const [activeHome, setActiveHome] = useState(false);
    const [activeUser, setActiveUser] = useState(false);
    const [activeProject, setActiveProject] = useState(false);
    const [activeInvest, setActiveInvest] = useState(false);
    const [activePayment, setActivePayment] = useState(false);
    const [activeOrganisation, setActiveOrganisation] = useState(false);
    const [activeSetting, setActiveSetting] = useState(false);

    const openUserDrawer = useSelector((state) => state.layout.openUserDrawer);
    const openProjectDrawer = useSelector((state) => state.layout.openProjectDrawer);
    const openInvestDrawer = useSelector((state) => state.layout.openInvestDrawer);
    const openPaymentDrawer = useSelector((state) => state.layout.openPaymentDrawer);
    const openOrganisationDrawer = useSelector((state) => state.layout.openOrganisationDrawer);
    const openSettingDrawer = useSelector((state) => state.layout.openSettingDrawer);

    useEffect(() => {
        handleUserRoute(pathname);
        handleProjectRoute(pathname);
        handleInvestRoute(pathname);
        handlePaymentRoute(pathname);
        handleOrganisationRoute(pathname);
        handleSettingRoute(pathname);
    }, [pathname])

    const handleDrawer = () => [
        setDrawer(!drawer)
    ]

    const handleHome = () => {
        onChangeDrawer(false)

        setDrawer(false)
        setUser(false)
        setProjet(false)
        setInvestissement(false)
        setPaiement(false)
        setOrganisation(false)
        setSettings(false)

        setActiveHome(true)
        setActiveUser(false);
        setActiveProject(false);
        setActiveInvest(false);
        setActiveOrganisation(false);
        setActiveSetting(false);

        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenProjectDrawer(false))
        dispatch(setOpenInvestDrawer(false))
        dispatch(setOpenPaymentDrawer(false))
        dispatch(setOpenOrganisationDrawer(false))
        dispatch(setOpenSettingDrawer(false))
    }

    const handleUserRoute = (routePathname) => {
        const userRoutes = ["/userlist", '/financier', '/investisseurs', '/porteurdeprojet', '/requete']
        if (userRoutes.includes(routePathname)) setActiveUser(true)
    }
    const handleProjectRoute = (routePathname) => {
        const projectRoutes = ["/project", '/rapport', '/project-detail']
        if (projectRoutes.includes(routePathname)) setActiveProject(true)
    }
    const handleInvestRoute = (routePathname) => {
        const investRoutes = ["/invest", '/rapport-invest']
        if (investRoutes.includes(routePathname)) setActiveInvest(true)
    }
    const handlePaymentRoute = (routePathname) => {
        const paymentRoutes = ["/payment", '/end-payment', '/pending-payment', '/payment-detail']
        if (paymentRoutes.includes(routePathname)) setActivePayment(true)
    }
    const handleOrganisationRoute = (routePathname) => {
        const organisationRoutes = ["/organisation", '/organisation-porteur-de-projet', '/organisation-investisseur']
        if (organisationRoutes.includes(routePathname)) setActiveOrganisation(true)
    }
    const handleSettingRoute = (routePathname) => {
        const settingRoutes = ["/setting", '/setting-role', '/setting-preference', '/setting-role-detail']
        if (settingRoutes.includes(routePathname)) setActiveSetting(true)
    }

    const handleUsers = () => {
        if (user || openUserDrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false);
            }
            setUser(false);
            dispatch(setOpenUserDrawer(false))
        } else {
            if (!isDrawerOpen) {
                onChangeDrawer(true);

            }
            setUser(true);
            dispatch(setOpenUserDrawer(!openUserDrawer))
            history.push('/userlist')
        }
        setProjet(false)
        setInvestissement(false)
        setPaiement(false)
        setOrganisation(false)
        setSettings(false)

        dispatch(setOpenProjectDrawer(false))
        dispatch(setOpenInvestDrawer(false))
        dispatch(setOpenPaymentDrawer(false))
        dispatch(setOpenOrganisationDrawer(false))
        dispatch(setOpenSettingDrawer(false))
    }

    const handleProjets = () => {
        if (projet || openProjectDrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false);
            }
            setProjet(false);
            dispatch(setOpenProjectDrawer(false))
        } else {
            if (!isDrawerOpen) {
                onChangeDrawer(true);
            }
            setProjet(true);
            dispatch(setOpenProjectDrawer(!openProjectDrawer))
            history.push('/project')
        }
        setUser(false)
        setInvestissement(false)
        setPaiement(false)
        setOrganisation(false)
        setSettings(false)

        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenInvestDrawer(false))
        dispatch(setOpenPaymentDrawer(false))
        dispatch(setOpenOrganisationDrawer(false))
        dispatch(setOpenSettingDrawer(false))
    }

    const handleInvestissement = () => {
        if (investissement || openInvestDrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false);
            }
            setInvestissement(false);
            dispatch(setOpenInvestDrawer(false))
        } else {
            if (!isDrawerOpen) {
                onChangeDrawer(true);
            }
            setInvestissement(true);
            dispatch(setOpenInvestDrawer(!openInvestDrawer))
            history.push('/invest');

        }
        setProjet(false)
        setUser(false)
        setPaiement(false)
        setOrganisation(false)
        setSettings(false)

        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenProjectDrawer(false))
        dispatch(setOpenPaymentDrawer(false))
        dispatch(setOpenOrganisationDrawer(false))
        dispatch(setOpenSettingDrawer(false))
    }

    const handlePaiement = () => {
        if (paiement || openPaymentDrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false);
            }
            setPaiement(false);
            dispatch(setOpenPaymentDrawer(false))
        } else {
            if (!isDrawerOpen) {
                onChangeDrawer(true);
            }
            setPaiement(true);
            dispatch(setOpenPaymentDrawer(!openPaymentDrawer))
            history.push('/payment')
        }
        setInvestissement(false)
        setProjet(false)
        setUser(false)
        setOrganisation(false)
        setSettings(false)

        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenProjectDrawer(false))
        dispatch(setOpenInvestDrawer(false))
        dispatch(setOpenOrganisationDrawer(false))
        dispatch(setOpenSettingDrawer(false))
    }

    const handleOrganisation = () => {
        if (organisation || openOrganisationDrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false);
            }
            setOrganisation(false);
            dispatch(setOpenOrganisationDrawer(false))
        } else {
            if (!isDrawerOpen) {
                onChangeDrawer(true);
            }
            setOrganisation(true);
            dispatch(setOpenOrganisationDrawer(!openOrganisationDrawer))
            history.push('/organisation');
        }
        setPaiement(false)
        setInvestissement(false)
        setProjet(false)
        setUser(false)
        setSettings(false)

        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenProjectDrawer(false))
        dispatch(setOpenInvestDrawer(false))
        dispatch(setOpenPaymentDrawer(false))
        dispatch(setOpenSettingDrawer(false))

    }

    const handleSettings = () => {
        if (settings || openSettingDrawer) {
            if (isDrawerOpen) {
                onChangeDrawer(false);
            }
            setSettings(false);
            dispatch(setOpenSettingDrawer(false))
        } else {
            if (!isDrawerOpen) {
                onChangeDrawer(true);
            }
            setSettings(true);
            dispatch(setOpenSettingDrawer(!openSettingDrawer))
            history.push('/setting')
        }
        setOrganisation(false)
        setPaiement(false)
        setInvestissement(false)
        setProjet(false)
        setUser(false)

        dispatch(setOpenUserDrawer(false))
        dispatch(setOpenProjectDrawer(false))
        dispatch(setOpenInvestDrawer(false))
        dispatch(setOpenPaymentDrawer(false))
        dispatch(setOpenOrganisationDrawer(false))
    }

    return (
        <div id="kt_app_sidebar" class="app-sidebar" data-kt-drawer="true"

            data-kt-app-sidebar-secondary-collapse={drawer ? "on" : ""}
            data-kt-app-sidebar-push-footer="true" data-kt-app-sidebar-secondary-enabled="true" >

            <div class="app-sidebar-primary" style={{ backgroundColor: '#101010' }}>

                <div class="app-sidebar-logo d-none d-md-flex flex-center pt-10 mb-5 mb-lg-17" id="kt_app_sidebar_logo">

                    <a href="../../demo29/dist/index.html">
                        <img alt="Logo" src={Logo} class="h-80px" />
                    </a>
                </div>

                <div class="app-sidebar-menu flex-grow-1 hover-scroll-overlay-y scroll-ps mx-2 my-5" id="kt_aside_menu_wrapper" data-kt-scroll="true" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_logo, #kt_app_sidebar_footer" data-kt-scroll-wrappers="#kt_app_sidebar_menu" data-kt-scroll-offset="5px">

                    <div id="kt_aside_menu" class="menu menu-rounded menu-column menu-title-gray-600 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-6" data-kt-menu="true">

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item here show py-2 ">

                            <span class="menu-link menu-center" onClick={handleHome}>
                                <Link to='/home' >

                                    <span class="menu-icon me-0 ">
                                        <i class="ki-outline ki-home-2 fs-2x " style={{ color: pathname === "/home" ? '#fff' : '#8e736a' }}></i>
                                        {/* <span class="tooltiptext" style={{fontSize:12}}>Dashboard</span> */}
                                    </span>
                                </Link>
                            </span>

                            <div class="menu-sub menu-sub-dropdown px-2 py-4 w-250px mh-75 overflow-auto">

                                <div class="menu-item" >

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Home</span>
                                    </div>

                                </div>

                                <div class="menu-item">

                                    <a class="menu-link active" href="../../demo29/dist/index.html">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Default</span>
                                    </a>

                                </div>

                                <div class="menu-item">

                                    <a class="menu-link" href="../../demo29/dist/dashboards/ecommerce.html">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">eCommerce</span>
                                    </a>

                                </div>

                                <div class="menu-item">

                                    <a class="menu-link" href="../../demo29/dist/dashboards/projects.html">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Projects</span>
                                    </a>

                                </div>

                                <div class="menu-item">

                                    <a class="menu-link" href="../../demo29/dist/dashboards/online-courses.html">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Online Courses</span>
                                    </a>

                                </div>

                                <div class="menu-item">

                                    <a class="menu-link" href="../../demo29/dist/dashboards/marketing.html">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Marketing</span>
                                    </a>

                                </div>

                                <div class="menu-inner flex-column collapse" id="kt_app_sidebar_menu_dashboards_collapse">

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/bidding.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Bidding</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/pos.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">POS System</span>
                                        </a>

                                    </div>
                                    <div class="menu-item">
                                        <a class="menu-link" href="../../demo29/dist/dashboards/call-center.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Call Center</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/logistics.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Logistics</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/website-analytics.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Website Analytics</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/finance-performance.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Finance Performance</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/store-analytics.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Store Analytics</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/social.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Social</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/delivery.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Delivery</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/crypto.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Crypto</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/school.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">School</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/dashboards/podcast.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Podcast</span>
                                        </a>

                                    </div>

                                    <div class="menu-item">

                                        <a class="menu-link" href="../../demo29/dist/landing.html">
                                            <span class="menu-bullet">
                                                <span class="bullet bullet-dot"></span>
                                            </span>
                                            <span class="menu-title">Landing</span>
                                        </a>

                                    </div>

                                </div>
                                <div class="menu-item">
                                    <div class="menu-content">
                                        <a class="btn btn-flex btn-color-primary d-flex flex-stack fs-base p-0 ms-2 mb-2 toggle collapsible collapsed" data-bs-toggle="collapse" href="#kt_app_sidebar_menu_dashboards_collapse" data-kt-toggle-text="Show Less">
                                            <span data-kt-toggle-text-target="true">Show 12 More</span>
                                            <i class="ki-outline ki-minus-square toggle-on fs-2 me-0"></i>
                                            <i class="ki-outline ki-plus-square toggle-off fs-2 me-0"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleUsers}>

                                    <i class="ki-outline  ki-duotone ki-profile-user fs-2x" style={{ color: (pathname === '/userlist' || activeUser) ? '#fff' : '#8e736a' }}></i>
                                </span>
                            </span>

                            <div class="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Pages</span>
                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">User Profile</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/user-profile/overview.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Overview</span>
                                            </a>
                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/user-profile/projects.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Projects</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/user-profile/campaigns.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Campaigns</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/user-profile/documents.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Documents</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/user-profile/followers.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Followers</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/user-profile/activity.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Activity</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Account</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/overview.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Overview</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/settings.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Settings</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/security.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Security</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/activity.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Activity</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/billing.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Billing</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/statements.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Statements</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/referrals.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Referrals</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/api-keys.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">API Keys</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/account/logs.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Logs</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Authentication</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Corporate Layout</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/corporate/sign-in.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-in</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/corporate/sign-up.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-up</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/corporate/two-factor.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Two-Factor</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/corporate/reset-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Reset Password</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/corporate/new-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Password</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Overlay Layout</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/overlay/sign-in.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-in</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/overlay/sign-up.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-up</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/overlay/two-factor.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Two-Factor</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/overlay/reset-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Reset Password</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/overlay/new-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Password</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Creative Layout</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/creative/sign-in.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-in</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/creative/sign-up.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-up</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/creative/two-factor.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Two-Factor</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/creative/reset-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Reset Password</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/creative/new-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Password</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Fancy Layout</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/fancy/sign-in.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-in</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/fancy/sign-up.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sign-up</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/fancy/two-factor.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Two-Factor</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/fancy/reset-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Reset Password</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/layouts/fancy/new-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Password</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Email Templates</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/welcome-message.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Welcome Message</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/reset-password.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Reset Password</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/subscription-confirmed.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Subscription Confirmed</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/card-declined.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Credit Card Declined</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/promo-1.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Promo 1</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/promo-2.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Promo 2</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/authentication/email/promo-3.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Promo 3</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/extended/multi-steps-sign-up.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Multi-steps Sign-up</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/welcome.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Welcome Message</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/verify-email.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Verify Email</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/coming-soon.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Coming Soon</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/password-confirmation.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Password Confirmation</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/account-deactivated.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Account Deactivation</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/error-404.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Error 404</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/authentication/general/error-500.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Error 500</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Corporate</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/about.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">About</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/team.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Our Team</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/contact.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Contact Us</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/licenses.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Licenses</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/sitemap.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Sitemap</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Social</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/social/feeds.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Feeds</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/social/activity.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Activty</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/social/followers.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Followers</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/social/settings.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Settings</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Blog</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/blog/home.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Blog Home</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/blog/post.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Blog Post</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Careers</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/careers/list.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Careers List</span>
                                            </a>

                                        </div>

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/pages/careers/apply.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Careers Apply</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleProjets}>
                                    <i class="ki-outline ki-duotone ki-file  fs-2x" style={{ color: (pathname === '/project' || activeProject) ? '#fff' : '#8e736a' }}></i>
                                </span>
                            </span>

                            <div class="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Utilities</span>
                                    </div>

                                </div>

                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Modals</span>
                                        <span class="menu-arrow"></span>
                                    </span>

                                    <div class="menu-sub menu-sub-accordion menu-active-bg">

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">General</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/general/invite-friends.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Invite Friends</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/general/view-users.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">View Users</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/general/select-users.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Select Users</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/general/upgrade-plan.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Upgrade Plan</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/general/share-earn.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Share & Earn</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Forms</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/forms/new-target.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Target</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/forms/new-card.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Card</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/forms/new-address.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">New Address</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/forms/create-api-key.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Create API Key</span>
                                                    </a>

                                                </div>

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/forms/bidding.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Bidding</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Wizards</span>
                                                <span class="menu-arrow"></span>
                                            </span>

                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/create-app.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Create App</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/create-campaign.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Create Campaign</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/create-account.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Create Business Acc</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/create-project.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Create Project</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/top-up-wallet.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Top Up Wallet</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/offer-a-deal.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Offer a Deal</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/two-factor-authentication.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Two Factor Auth</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Search</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/search/users.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Users</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/utilities/modals/search/select-location.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Select Location</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Wizards</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion menu-active-bg">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/horizontal.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Horizontal</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/vertical.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Vertical</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/two-factor-authentication.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Two Factor Auth</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/create-app.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Create App</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/create-campaign.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Create Campaign</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/create-account.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Create Account</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/create-project.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Create Project</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/modals/wizards/top-up-wallet.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Top Up Wallet</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/wizards/offer-a-deal.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Offer a Deal</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Search</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion menu-active-bg">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/search/horizontal.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Horizontal</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/search/vertical.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Vertical</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/search/users.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Users</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/utilities/search/select-location.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Location</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-link menu-center" onClick={handleInvestissement}>
                                <span class="menu-icon me-0">
                                    <i class="ki-outline ki-duotone ki-abstract-45 fs-2x" style={{ color: (pathname === '/invest' || activeInvest) ? '#fff' : '#8e736a' }}></i>
                                </span>
                            </span>


                            <div class="menu-sub menu-sub-dropdown menu-sub-indention px-2 py-4 w-250px mh-75 overflow-auto">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Apps</span>
                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-rocket fs-2"></i>
                                        </span>
                                        <span class="menu-title">Projects</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/list.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">My Projects</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/project.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">View Project</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/targets.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Targets</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/budget.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Budget</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/users.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Users</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/files.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Files</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/activity.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Activity</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/projects/settings.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Settings</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-handcart fs-2"></i>
                                        </span>
                                        <span class="menu-title">eCommerce</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Catalog</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/catalog/products.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Products</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/catalog/categories.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Categories</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/catalog/add-product.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Add Product</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/catalog/edit-product.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Edit Product</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/catalog/add-category.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Add Category</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/catalog/edit-category.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Edit Category</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Sales</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/sales/listing.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Orders Listing</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/sales/details.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Order Details</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/sales/add-order.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Add Order</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/sales/edit-order.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Edit Order</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Customers</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/customers/listing.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Customer Listing</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/customers/details.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Customer Details</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Reports</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/reports/view.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Products Viewed</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/reports/sales.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Sales</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/reports/returns.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Returns</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/reports/customer-orders.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Customer Orders</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/ecommerce/reports/shipping.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Shipping</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/ecommerce/settings.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Settings</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-phone fs-2"></i>
                                        </span>
                                        <span class="menu-title">Contacts</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/contacts/getting-started.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Getting Started</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/contacts/add-contact.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Add Contact</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/contacts/edit-contact.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Edit Contact</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/contacts/view-contact.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">View Contact</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-chart fs-2"></i>
                                        </span>
                                        <span class="menu-title">Support Center</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/support-center/overview.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Overview</span>
                                            </a>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Tickets</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/support-center/tickets/list.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Tickets List</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/support-center/tickets/view.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">View Ticket</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Tutorials</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/support-center/tutorials/list.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Tutorials List</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/support-center/tutorials/post.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Tutorial Post</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/support-center/faq.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">FAQ</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/support-center/licenses.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Licenses</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/support-center/contact.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Contact Us</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-shield-tick fs-2"></i>
                                        </span>
                                        <span class="menu-title">User Management</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion mb-1">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Users</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/user-management/users/list.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Users List</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/user-management/users/view.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">View User</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Roles</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/user-management/roles/list.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Roles List</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/user-management/roles/view.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">View Role</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/user-management/permissions.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Permissions</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-briefcase fs-2"></i>
                                        </span>
                                        <span class="menu-title">Customers</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/customers/getting-started.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Getting Started</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/customers/list.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Customer Listing</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/customers/view.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Customer Details</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-map fs-2"></i>
                                        </span>
                                        <span class="menu-title">Subscription</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/subscriptions/getting-started.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Getting Started</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/subscriptions/list.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Subscription List</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/subscriptions/add.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Add Subscription</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/subscriptions/view.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">View Subscription</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-credit-cart fs-2"></i>
                                        </span>
                                        <span class="menu-title">Invoice Manager</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                            <span class="menu-link">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">View Invoices</span>
                                                <span class="menu-arrow"></span>
                                            </span>


                                            <div class="menu-sub menu-sub-accordion menu-active-bg">

                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/invoices/view/invoice-1.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Invoice 1</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/invoices/view/invoice-2.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Invoice 2</span>
                                                    </a>

                                                </div>


                                                <div class="menu-item">

                                                    <a class="menu-link" href="../../demo29/dist/apps/invoices/view/invoice-3.html">
                                                        <span class="menu-bullet">
                                                            <span class="bullet bullet-dot"></span>
                                                        </span>
                                                        <span class="menu-title">Invoice 3</span>
                                                    </a>

                                                </div>

                                            </div>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/invoices/create.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Create Invoice</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-file-added fs-2"></i>
                                        </span>
                                        <span class="menu-title">File Manager</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/file-manager/folders.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Folders</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/file-manager/files.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Files</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/file-manager/blank.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Blank Directory</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/file-manager/settings.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Settings</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-sms fs-2"></i>
                                        </span>
                                        <span class="menu-title">Inbox</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/inbox/listing.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Messages</span>
                                                <span class="menu-badge">
                                                    <span class="badge badge-success">3</span>
                                                </span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/inbox/compose.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Compose</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/inbox/reply.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">View & Reply</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div data-kt-menu-trigger="click" class="menu-item menu-accordion">

                                    <span class="menu-link">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-message-text-2 fs-2"></i>
                                        </span>
                                        <span class="menu-title">Chat</span>
                                        <span class="menu-arrow"></span>
                                    </span>


                                    <div class="menu-sub menu-sub-accordion">

                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/chat/private.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Private Chat</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/chat/group.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Group Chat</span>
                                            </a>

                                        </div>


                                        <div class="menu-item">

                                            <a class="menu-link" href="../../demo29/dist/apps/chat/drawer.html">
                                                <span class="menu-bullet">
                                                    <span class="bullet bullet-dot"></span>
                                                </span>
                                                <span class="menu-title">Drawer Chat</span>
                                            </a>

                                        </div>

                                    </div>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="../../demo29/dist/apps/calendar.html">
                                        <span class="menu-icon">
                                            <i class="ki-outline ki-calendar-8 fs-2"></i>
                                        </span>
                                        <span class="menu-title">Calendar</span>
                                    </a>

                                </div>

                            </div>

                        </div>


                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handlePaiement}>
                                    <i class="ki-outline ki-duotone ki-wallet fs-2x" style={{ color: (pathname === '/payment' || activePayment) ? '#fff' : '#8e736a' }}></i>
                                </span>
                            </span>


                            <div class="menu-sub menu-sub-dropdown px-2 py-4 w-200px w-lg-225px mh-75 overflow-auto">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Help</span>
                                    </div>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs/base/utilities" target="_blank" title="Check out over 200 in-house components" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Components</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs" target="_blank" title="Check out the complete documentation" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Documentation</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/metronic8/demo29/layout-builder.html" title="Build your layout and export HTML for server side integration" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Layout Builder</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs/getting-started/changelog" target="_blank">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Changelog v8.2.0</span>
                                    </a>

                                </div>

                            </div>

                        </div>


                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleOrganisation}>
                                    <i class="ki-outline ki-duotone ki-people fs-2x" style={{ color: (pathname === '/organisation' || activeOrganisation) ? '#fff' : '#8e736a' }}></i>
                                </span>
                            </span>


                            <div class="menu-sub menu-sub-dropdown px-2 py-4 w-200px w-lg-225px mh-75 overflow-auto">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Help</span>
                                    </div>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs/base/utilities" target="_blank" title="Check out over 200 in-house components" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Components</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs" target="_blank" title="Check out the complete documentation" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Documentation</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/metronic8/demo29/layout-builder.html" title="Build your layout and export HTML for server side integration" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Layout Builder</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs/getting-started/changelog" target="_blank">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Changelog v8.2.0</span>
                                    </a>

                                </div>

                            </div>

                        </div>

                        <div data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="right-start" class="menu-item py-2">

                            <span class="menu-icon me-0">
                                <span class="menu-link menu-center" onClick={handleSettings}>
                                    <i class="ki-outline ki-duotone ki-setting-2 fs-2x" style={{ color: (pathname === '/setting' || activeSetting) ? '#fff' : '#8e736a' }}></i>
                                </span>
                            </span>


                            <div class="menu-sub menu-sub-dropdown px-2 py-4 w-200px w-lg-225px mh-75 overflow-auto">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1">Help</span>
                                    </div>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs/base/utilities" target="_blank" title="Check out over 200 in-house components" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Components</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs" target="_blank" title="Check out the complete documentation" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Documentation</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/metronic8/demo29/layout-builder.html" title="Build your layout and export HTML for server side integration" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-dismiss="click" data-bs-placement="right">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Layout Builder</span>
                                    </a>

                                </div>


                                <div class="menu-item">

                                    <a class="menu-link" href="https://preview.keenthemes.com/html/metronic/docs/getting-started/changelog" target="_blank">
                                        <span class="menu-bullet">
                                            <span class="bullet bullet-dot"></span>
                                        </span>
                                        <span class="menu-title">Changelog v8.2.0</span>
                                    </a>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column flex-center pb-4 pb-lg-8" id="kt_app_sidebar_footer">

                <div onClick={() => history.push('/se-connecter')} class="cursor-pointer symbol symbol-40px symbol-circle" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-attach="parent" data-kt-menu-placement="right-end">
                    <i class="ki-outline ki-entrance-right fs-2x"></i>
                </div>
                {/* <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg menu-state-color fw-semibold py-4 fs-6 w-275px" data-kt-menu="true">
                    <>
                        <div class="menu-item px-3">
                            <div class="menu-content d-flex align-items-center px-3">

                                <div class="symbol symbol-50px me-5">
                                    <img alt="Logo" src="assets/media/avatars/300-7.jpg" />
                                </div>

                                <div class="d-flex flex-column">
                                    <div class="fw-bold d-flex align-items-center fs-5">Max Smith
                                        <span class="badge badge-light-success fw-bold fs-8 px-2 py-1 ms-2">Pro</span></div>
                                    <a href="#" class="fw-semibold text-muted text-hover-primary fs-7">max@kt.com</a>
                                </div>

                            </div>
                        </div>

                        <div class="separator my-2"></div>


                        <div class="menu-item px-5">
                            <a href="../../demo29/dist/account/overview.html" class="menu-link px-5">My Profile</a>
                        </div>


                        <div class="menu-item px-5">
                            <a href="../../demo29/dist/apps/projects/list.html" class="menu-link px-5">
                                <span class="menu-text">My Projects</span>
                                <span class="menu-badge">
                                    <span class="badge badge-light-danger badge-circle fw-bold fs-7">3</span>
                                </span>
                            </a>
                        </div>


                        <div class="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-end" data-kt-menu-offset="-15px, 0">
                            <a href="#" class="menu-link px-5">
                                <span class="menu-title">My Subscription</span>
                                <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/referrals.html" class="menu-link px-5">Referrals</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/billing.html" class="menu-link px-5">Billing</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/statements.html" class="menu-link px-5">Payments</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/statements.html" class="menu-link d-flex flex-stack px-5">Statements
                                        <span class="ms-2 lh-0" data-bs-toggle="tooltip" title="View your statements">
                                            <i class="ki-outline ki-information-5 fs-5"></i>
                                        </span></a>
                                </div>


                                <div class="separator my-2"></div>


                                <div class="menu-item px-3">
                                    <div class="menu-content px-3">
                                        <label class="form-check form-switch form-check-custom form-check-solid">
                                            <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />
                                            <span class="form-check-label text-muted fs-7">Notifications</span>
                                        </label>
                                    </div>
                                </div>

                            </div>

                        </div>


                        <div class="menu-item px-5">
                            <a href="../../demo29/dist/account/statements.html" class="menu-link px-5">My Statements</a>
                        </div>


                        <div class="separator my-2"></div>


                        <div class="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="-15px, 0">
                            <a href="#" class="menu-link px-5">
                                <span class="menu-title position-relative">Mode
                                    <span class="ms-5 position-absolute translate-middle-y top-50 end-0">
                                        <i class="ki-outline ki-night-day theme-light-show fs-2"></i>
                                        <i class="ki-outline ki-moon theme-dark-show fs-2"></i>
                                    </span></span>
                            </a>

                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px" data-kt-menu="true" data-kt-element="theme-mode-menu">

                                <div class="menu-item px-3 my-0">
                                    <a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="light">
                                        <span class="menu-icon" data-kt-element="icon">
                                            <i class="ki-outline ki-night-day fs-2"></i>
                                        </span>
                                        <span class="menu-title">Light</span>
                                    </a>
                                </div>


                                <div class="menu-item px-3 my-0">
                                    <a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
                                        <span class="menu-icon" data-kt-element="icon">
                                            <i class="ki-outline ki-moon fs-2"></i>
                                        </span>
                                        <span class="menu-title">Dark</span>
                                    </a>
                                </div>


                                <div class="menu-item px-3 my-0">
                                    <a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="system">
                                        <span class="menu-icon" data-kt-element="icon">
                                            <i class="ki-outline ki-screen fs-2"></i>
                                        </span>
                                        <span class="menu-title">System</span>
                                    </a>
                                </div>

                            </div>

                        </div>


                        <div class="menu-item px-5" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-placement="left-start" data-kt-menu-offset="0, 0">
                            <a href="#" class="menu-link px-5">
                                <span class="menu-title position-relative">Language
                                    <span class="fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0">English
                                        <img class="w-15px h-15px rounded-1 ms-2" src="assets/media/flags/united-states.svg" alt="" /></span></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/settings.html" class="menu-link d-flex px-5 active">
                                        <span class="symbol symbol-20px me-4">
                                            <img class="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
                                        </span>English</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/settings.html" class="menu-link d-flex px-5">
                                        <span class="symbol symbol-20px me-4">
                                            <img class="rounded-1" src="assets/media/flags/spain.svg" alt="" />
                                        </span>Spanish</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/settings.html" class="menu-link d-flex px-5">
                                        <span class="symbol symbol-20px me-4">
                                            <img class="rounded-1" src="assets/media/flags/germany.svg" alt="" />
                                        </span>German</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/settings.html" class="menu-link d-flex px-5">
                                        <span class="symbol symbol-20px me-4">
                                            <img class="rounded-1" src="assets/media/flags/japan.svg" alt="" />
                                        </span>Japanese</a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="../../demo29/dist/account/settings.html" class="menu-link d-flex px-5">
                                        <span class="symbol symbol-20px me-4">
                                            <img class="rounded-1" src="assets/media/flags/france.svg" alt="" />
                                        </span>French</a>
                                </div>

                            </div>

                        </div>


                        <div class="menu-item px-5 my-1">
                            <a href="../../demo29/dist/account/settings.html" class="menu-link px-5">Account Settings</a>
                        </div>


                        <div class="menu-item px-5">
                            <a href="../../demo29/dist/authentication/layouts/corporate/sign-in.html" class="menu-link px-5">Sign Out</a>
                        </div>

                    </>
                </div> */}
                </div>
            </div>


            <div class="app-sidebar-secondary" style={{ backgroundColor: '#383838' }}>

                <div class="d-flex flex-column">
                    <div style={{ display: openUserDrawer || user ? 'block' : 'none', width: '18rem' }}>
                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                            {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                                        <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                            <span class="fs-2 fw-bolder text-white">Utilisateurs</span>
                        </div>

                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    </div>
                                </div>


                                <div class="menu-item">

                                    <Link to="/userlist" class="menu-link" style={{ backgroundColor: pathname === "/userlist" ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/userlist' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === "/userlist" ? 'white' : '#8e736a' }}>Tous les utilisateurs</span>
                                    </Link>

                                </div>


                                <div class="menu-item">

                                    <Link to="/financier" class="menu-link" style={{ backgroundColor: pathname === '/financier' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/financier' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === "/financier" ? 'white' : '#8e736a' }}>Financiers</span>
                                    </Link>

                                </div>


                                <div class="menu-item">

                                    <Link class="menu-link" to="/investisseurs" style={{ backgroundColor: pathname === '/investisseurs' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/investisseurs' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === "/investisseurs" ? 'white' : '#8e736a' }}>Investisseurs</span>
                                    </Link>

                                </div>


                                <div class="menu-item">

                                    <Link class="menu-link" to="/porteurdeprojet" style={{ backgroundColor: pathname === '/porteurdeprojet' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline  ki-duotone ki-profile-user fs-1x" style={{ color: pathname === '/porteurdeprojet' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === "/porteurdeprojet" ? 'white' : '#8e736a' }}>Porteurs de projets</span>
                                    </Link>

                                </div>

                                <span class="d-flex position-relative" style={{ color: 'white' }}>

                                    <span class="d-inline-block position-absolute h-1px bottom-0 end-0 start-0 translate rounded" style={{ backgroundColor: '#888' }}></span>

                                </span>


                                <div class="menu-item">

                                    <Link class="menu-link" to="/requete" style={{ backgroundColor: pathname === '/requete' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-abstract-16 fs-1x" style={{ color: pathname === '/requete' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === "/requete" ? 'white' : '#8e736a' }}>Requetes</span>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: projet || openProjectDrawer ? 'block' : 'none', width: '18rem' }}>
                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                            {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                                    <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                            <span class="fs-2 fw-bolder text-white">Projets</span>
                        </div>

                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    </div>
                                </div>


                                <div class="menu-item">

                                    <Link to="/project" class="menu-link" style={{ backgroundColor: pathname === '/project' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-file fs-1x" style={{ color: pathname === '/project' ? '#fff' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/project' ? '#fff' : '#8e736a' }}>Tous les projets</span>
                                    </Link>

                                </div>


                                <div class="menu-item">

                                    <Link to="/rapport" class="menu-link" style={{ backgroundColor: pathname === '/rapport' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-file  fs-1x" style={{ color: pathname === '/rapport' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/rapport' ? '#fff' : '#8e736a' }}>Rapport</span>
                                    </Link>

                                </div>


                            </div>
                        </div>

                    </div>
                    <div style={{ display: investissement || openInvestDrawer ? 'block' : 'none', width: '18rem' }}>
                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                            {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                                <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                            <span class="fs-2 fw-bolder text-white">Investissements</span>
                        </div>

                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    </div>
                                </div>



                                <div class="menu-item">

                                    <Link to="/invest" class="menu-link" style={{ backgroundColor: pathname === '/invest' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-abstract-45 fs-1x" style={{ color: pathname === '/invest' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/invest' ? '#fff' : '#8e736a' }}>Tous les investissements</span>
                                    </Link>

                                </div>


                                <div class="menu-item">

                                    <Link to="/rapport-invest" class="menu-link" style={{ backgroundColor: pathname === '/rapport-invest' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-abstract-45 fs-1x" style={{ color: pathname === '/rapport-invest' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/rapport-invest' ? '#fff' : '#8e736a' }}>Rapport</span>
                                    </Link>

                                </div>


                            </div>
                        </div>

                    </div>
                    <div style={{ display: paiement || openPaymentDrawer ? 'block' : 'none', width: '18rem' }}>
                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                            {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                            <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                            <span class="fs-2 fw-bolder text-white">Paiements</span>
                        </div>

                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    </div>
                                </div>


                                <div class="menu-item">

                                    <Link class="menu-link" to="/payment" style={{ backgroundColor: pathname === '/payment' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-wallet fs-1x" style={{ color: pathname === '/payment' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/payment' ? '#fff' : '#8e736a' }}>Tous les paiements</span>
                                    </Link>

                                </div>
                                <div class="menu-item">

                                    <Link to="/pending-payment" class="menu-link" style={{ backgroundColor: pathname === '/pending-payment' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-wallet fs-1x" style={{ color: pathname === '/pending-payment' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/pending-payment' ? 'white' : '#8e736a' }}>En cours</span>
                                    </Link>

                                </div>



                                <div class="menu-item">

                                    <Link to="/end-payment" class="menu-link" style={{ backgroundColor: pathname === '/end-payment' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-wallet fs-1x" style={{ color: pathname === '/end-payment' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/end-payment' ? 'white' : '#8e736a' }}>Terminés</span>
                                    </Link>

                                </div>


                            </div>
                        </div>

                    </div>
                    <div style={{ display: organisation || openOrganisationDrawer ? 'block' : 'none', width: '18rem' }}>
                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                            {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                        <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                            <span class="fs-2 fw-bolder text-white">Organisation</span>
                        </div>

                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    </div>
                                </div>


                                <div class="menu-item">

                                    <Link to="/organisation" class="menu-link" style={{ backgroundColor: pathname === '/organisation' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/organisation' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/organisation' ? 'white' : '#8e736a' }}>Financiers</span>
                                    </Link>

                                </div>
                                <div class="menu-item">

                                    <Link to="/organisation-investisseur" class="menu-link" style={{ backgroundColor: pathname === '/organisation-investisseur' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/organisation-investisseur' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/organisation-investisseur' ? 'white' : '#8e736a' }}>Investisseurs</span>
                                    </Link>

                                </div>



                                <div class="menu-item">

                                    <Link class="menu-link" to="/organisation-porteur-de-projet" style={{ backgroundColor: pathname === '/organisation-porteur-de-projet' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-people fs-1x" style={{ color: pathname === '/organisation-porteur-de-projet' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/organisation-porteur-de-projet' ? 'white' : '#8e736a' }}>Porteurs de projet</span>
                                    </Link>

                                </div>


                            </div>
                        </div>

                    </div>
                    <div style={{ display: settings || openSettingDrawer ? 'block' : 'none', width: '18rem' }}>
                        <div class="d-flex flex-column pt-10 ps-11" id="kt_app_sidebar_secondary_header">

                            {/* <a href="../../demo29/dist/index.html" class="d-flex align-items-center custom-link fs-6 fw-semibold mb-5">
                    <i class="ki-outline ki-black-left fs-2 me-3 text-white opacity-50"></i>Back to list</a> */}

                            <span class="fs-2 fw-bolder text-white">Parametres</span>
                        </div>

                        <div class="app-sidebar-secondary-menu menu menu-sub-indention menu-rounded menu-column menu-active-bg menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-arrow-gray-500 fw-semibold fs-6 py-4 py-lg-6" id="kt_app_sidebar_secondary_menu" data-kt-menu="true">
                            <div id="kt_app_sidebar_secondary_menu_wrapper" class="hover-scroll-y px-3 mx-3" data-kt-scroll="true" data-kt-scroll-activate="{default: true, lg: true}" data-kt-scroll-height="auto" data-kt-scroll-dependencies="#kt_app_sidebar_secondary_header" data-kt-scroll-wrappers="#kt_app_sidebar_secondary_menu" data-kt-scroll-offset="20px">

                                <div class="menu-item">

                                    <div class="menu-content">
                                        <span class="menu-section fs-5 fw-bolder ps-1 py-1" style={{ color: '#8e736a' }}>Actions</span>
                                    </div>
                                </div>


                                <div class="menu-item">
                                    <Link to="/setting" class="menu-link" style={{ backgroundColor: pathname === '/setting' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-setting-2 fs-1x" style={{ color: pathname === '/setting' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/setting' ? 'white' : '#8e736a' }}>Mon compte</span>
                                    </Link>

                                </div>

                                <div class="menu-item">

                                    <Link to="/setting-role" class="menu-link" style={{ backgroundColor: pathname === '/setting-role' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-setting-2  fs-1x" style={{ color: pathname === '/setting-role' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/setting-role' ? 'white' : '#8e736a' }}>Gestion des roles</span>
                                    </Link>

                                </div>


                                <div class="menu-item">

                                    <Link to="/setting-preference" class="menu-link" style={{ backgroundColor: pathname === '/setting-preference' ? '#8e736a' : '' }}>
                                        <span class="menu-icon me-0">

                                            <i class="ki-outline ki-duotone ki-setting-2  fs-1x" style={{ color: pathname === '/setting-preference' ? 'white' : '#8e736a' }}></i>

                                        </span>
                                        <span class="menu-title" style={{ color: pathname === '/setting-preference' ? 'white' : '#8e736a' }}>Préférences</span>
                                    </Link>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            {/* <button id="kt_app_sidebar_secondary_toggle" onClick={handleDrawer} class={drawer ? "app-sidebar-secondary-toggle btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-color-primary position-absolute translate-middle z-index-1 start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-4 active" : "app-sidebar-secondary-toggle btn btn-sm btn-icon bg-body btn-color-gray-600 btn-active-color-primary position-absolute translate-middle z-index-1 start-100 end-0 bottom-0 shadow-sm d-none d-lg-flex mb-4"} data-kt-toggle="true" data-kt-toggle-state="active" data-kt-toggle-target="body" data-kt-toggle-name="app-sidebar-secondary-collapse">
                <i class="ki-outline ki-arrow-left fs-2 rotate-180"></i>
            </button> */}

        </div>
    )
};

export default Navbar
