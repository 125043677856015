import React from 'react';
import User from "../../assets/images/user.jpg"
import Pdf from "../../assets/images/pdf.png"

export default function ProjectActivity() {
    return (
        <div>
            <div class="card">

                <div class="card-header card-header-stretch">

                    <div class="card-title d-flex align-items-center">
                        <i class="ki-outline ki-calendar-8 fs-1 text-primary me-3 lh-0"></i>
                        <h3 class="fw-bold m-0 text-gray-800">Jan 23, 2023</h3>
                    </div>


                    <div class="card-toolbar m-0">

                        <ul class="nav nav-tabs nav-line-tabs nav-stretch fs-6 border-0 fw-bold" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a id="kt_activity_today_tab" class="nav-link justify-content-center text-active-gray-800 active" data-bs-toggle="tab" role="tab" href="#kt_activity_today" aria-selected="true">Today</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a id="kt_activity_week_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_week" aria-selected="false" tabindex="-1">Week</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a id="kt_activity_month_tab" class="nav-link justify-content-center text-active-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_month" aria-selected="false" tabindex="-1">Month</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a id="kt_activity_year_tab" class="nav-link justify-content-center text-active-gray-800 text-hover-gray-800" data-bs-toggle="tab" role="tab" href="#kt_activity_year" aria-selected="false" tabindex="-1">2023</a>
                            </li>
                        </ul>

                    </div>

                </div>


                <div class="card-body">

                    <div class="tab-content">

                        <div id="kt_activity_today" class="card-body p-0 tab-pane fade show active" role="tabpanel" aria-labelledby="kt_activity_today_tab">

                            <div class="timeline">

                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px me-4">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-message-text-2 fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">There are 2 new tasks for you in “AirPlus Mobile App” project:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Nina Nilson" data-bs-original-title="Nina Nilson" data-kt-initialized="1">
                                                    <img src={User} alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">

                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-5">

                                                <a href="../../demo29/dist/apps/projects/project.html" class="fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px">Meeting with customer</a>


                                                <div class="min-w-175px pe-2">
                                                    <span class="badge badge-light text-muted">Application Design</span>
                                                </div>


                                                <div class="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px pe-2">

                                                    <div class="symbol symbol-circle symbol-25px">
                                                        <img src={User} alt="img" />
                                                    </div>


                                                    <div class="symbol symbol-circle symbol-25px">
                                                        <img src={User} alt="img" />
                                                    </div>


                                                    <div class="symbol symbol-circle symbol-25px">
                                                        <div class="symbol-label fs-8 fw-semibold bg-primary text-inverse-primary">A</div>
                                                    </div>

                                                </div>


                                                <div class="min-w-125px pe-2">
                                                    <span class="badge badge-light-primary">In Progress</span>
                                                </div>


                                                <a href="../../demo29/dist/apps/projects/project.html" class="btn btn-sm btn-light btn-active-light-primary">View</a>

                                            </div>


                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-750px px-7 py-3 mb-0">

                                                <a href="../../demo29/dist/apps/projects/project.html" class="fs-5 text-dark text-hover-primary fw-semibold w-375px min-w-200px">Project Delivery Preparation</a>


                                                <div class="min-w-175px">
                                                    <span class="badge badge-light text-muted">CRM System Development</span>
                                                </div>


                                                <div class="symbol-group symbol-hover flex-nowrap flex-grow-1 min-w-100px">

                                                    <div class="symbol symbol-circle symbol-25px">
                                                        <img src={User} alt="img" />
                                                    </div>


                                                    <div class="symbol symbol-circle symbol-25px">
                                                        <div class="symbol-label fs-8 fw-semibold bg-success text-inverse-primary">B</div>
                                                    </div>

                                                </div>


                                                <div class="min-w-125px">
                                                    <span class="badge badge-light-success">Completed</span>
                                                </div>


                                                <a href="../../demo29/dist/apps/projects/project.html" class="btn btn-sm btn-light btn-active-light-primary">View</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-flag fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n2">

                                        <div class="overflow-auto pe-3">

                                            <div class="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Alan Nilson" data-bs-original-title="Alan Nilson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-1.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="mb-5 pe-3">

                                            <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Jan Hummer" data-bs-original-title="Jan Hummer" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">

                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="" class="w-30px me-3" src={Pdf} />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="../../demo29/dist/apps/projects/project.html" class="fs-6 fw-bold" style={{color: "#8e736a"}}>Finance KPI App Guidelines</a>


                                                        <div class="text-gray-400">1.9mb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 fw-bold" style={{color: "#8e736a"}}>Client UAT Testing Results</a>


                                                        <div class="text-gray-400">18kb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 fw-bold" style={{color: "#8e736a"}}>Finance Reports</a>


                                                        <div class="text-gray-400">20mb</div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">Task
                                                <a href="#" class=" fw-bold me-1" style={{color: '#8e736a'}}>#45890</a>merged with
                                                <a href="#" class=" fw-bold me-1" style={{color: '#8e736a'}}>#45890</a>in “Ads Pro Admin Dashboard project:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Nina Nilson" data-bs-original-title="Nina Nilson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Marcus Dotson" data-bs-original-title="Marcus Dotson" data-kt-initialized="1">
                                                    <img src={User} alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">

                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src={"assets/media/stock/600x400/img-29.jpg"} />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New case
                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>#67890</a>is assigned to you in Multi-platform Database Design project</div>


                                            <div class="overflow-auto pb-5">

                                                <div class="d-flex align-items-center mt-1 fs-6">

                                                    <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>


                                                    <a href="#" class="text-primary fw-bold me-1" style={{color: "#8e736a"}}>Alice Tan</a>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">You have received a new order:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Placed at 5:05 AM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Robert Rich" data-bs-original-title="Robert Rich" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-4.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">

                                            <div class="notice d-flex rounded border border-dashed min-w-lg-600px flex-shrink-0 p-6" style={{backgroundColor: '#8e736a'}}>

                                                <i class="ki-outline ki-devices-2 fs-2tx me-4" style={{color: "#fff"}}></i>


                                                <div class="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap" >

                                                    <div class="mb-3 mb-md-0 fw-semibold">
                                                        <h4 class="fw-bold" style={{color: "#fff"}}>Database Backup Process Completed!</h4>
                                                        <div class="fs-6 pe-7" style={{color: "#fff"}}>Login into Admin Dashboard to make sure the data integrity is OK</div>
                                                    </div>


                                                    <a href="#" class="btn px-6 align-self-center text-nowrap" style={{backgroundColor: '#fff', color: "#8e736a"}}>Proceed</a>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-basket fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New order
                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>#67890</a>is placed for Workshow Planning &amp; Budget Estimation</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Placed at 4:23 PM by</div>


                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>Jimmy Bold</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div id="kt_activity_week" class="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_week_tab">

                            <div class="timeline">

                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-flag fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n2">

                                        <div class="overflow-auto pe-3">

                                            <div class="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Alan Nilson" data-bs-original-title="Alan Nilson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-1.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="mb-5 pe-3">

                                            <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Jan Hummer" data-bs-original-title="Jan Hummer" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">

                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="" class="w-30px me-3" src="assets/media/svg/files/pdf.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="../../demo29/dist/apps/projects/project.html" class="fs-6" style={{color: "#8e736a"}}>Finance KPI App Guidelines</a>


                                                        <div class="text-gray-400">1.9mb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>


                                                        <div class="text-gray-400">18kb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>


                                                        <div class="text-gray-400">20mb</div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">Task
                                                <a href="#" class=" fw-bold me-1" style={{color: '#8e736a'}}>#45890</a>merged with
                                                <a href="#" class=" fw-bold me-1" style={{color: '#8e736a'}}>#45890</a>in “Ads Pro Admin Dashboard project:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Nina Nilson" data-bs-original-title="Nina Nilson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Marcus Dotson" data-bs-original-title="Marcus Dotson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">

                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New case
                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>#67890</a>is assigned to you in Multi-platform Database Design project</div>


                                            <div class="overflow-auto pb-5">

                                                <div class="d-flex align-items-center mt-1 fs-6">

                                                    <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>


                                                    <a href="#" class="text-primary fw-bold me-1">Alice Tan</a>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div id="kt_activity_month" class="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_month_tab">

                            <div class="timeline">

                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Marcus Dotson" data-bs-original-title="Marcus Dotson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-2.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">

                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New case
                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>#67890</a>is assigned to you in Multi-platform Database Design project</div>


                                            <div class="overflow-auto pb-5">

                                                <div class="d-flex align-items-center mt-1 fs-6">

                                                    <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>


                                                    <a href="#" class="text-primary fw-bold me-1">Alice Tan</a>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-basket fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New order
                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>#67890</a>is placed for Workshow Planning &amp; Budget Estimation</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Placed at 4:23 PM by</div>


                                                <a href="#" class=" fw-bold me-1" style={{color: "#8e736a"}}>Jimmy Bold</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-flag fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n2">

                                        <div class="overflow-auto pe-3">

                                            <div class="fs-5 fw-semibold mb-2">Invitation for crafting engaging designs that speak human workshop</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Alan Nilson" data-bs-original-title="Alan Nilson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-1.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="mb-5 pe-3">

                                            <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Jan Hummer" data-bs-original-title="Jan Hummer" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">

                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="" class="w-30px me-3" src="assets/media/svg/files/pdf.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="../../demo29/dist/apps/projects/project.html" class="fs-6 text-hover-primary fw-bold">Finance KPI App Guidelines</a>


                                                        <div class="text-gray-400">1.9mb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>


                                                        <div class="text-gray-400">18kb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>


                                                        <div class="text-gray-400">20mb</div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">Task
                                                <a href="#" class=" fw-bold me-1" style={{color: '#8e736a'}}>#45890</a>merged with
                                                <a href="#" class=" fw-bold me-1" style={{color: '#8e736a'}}>#45890</a>in “Ads Pro Admin Dashboard project:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Nina Nilson" data-bs-original-title="Nina Nilson" data-kt-initialized="1">
                                                    <img src={User} alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>


                        <div id="kt_activity_year" class="card-body p-0 tab-pane fade show" role="tabpanel" aria-labelledby="kt_activity_year_tab">

                            <div class="timeline">

                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-disconnect fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="mb-5 pe-3">

                                            <a href="#" class="fs-5 fw-semibold text-gray-800 text-hover-primary mb-2">3 New Incoming Project Files:</a>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Sent at 10:30 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Jan Hummer" data-bs-original-title="Jan Hummer" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-23.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-5">

                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="" class="w-30px me-3" src={Pdf} />


                                                    <div class="ms-1">

                                                        <a href="../../demo29/dist/apps/projects/project.html" class="fs-6 fw-bold" style={{color: "#8e736a"}}>Finance KPI App Guidelines</a>


                                                        <div class="text-gray-400">1.9mb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center pe-10 pe-lg-20">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/doc.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 text-hover-primary fw-bold">Client UAT Testing Results</a>


                                                        <div class="text-gray-400">18kb</div>

                                                    </div>

                                                </div>


                                                <div class="d-flex flex-aligns-center">

                                                    <img alt="../../demo29/dist/apps/projects/project.html" class="w-30px me-3" src="assets/media/svg/files/css.svg" />


                                                    <div class="ms-1 fw-semibold">

                                                        <a href="#" class="fs-6 text-hover-primary fw-bold">Finance Reports</a>


                                                        <div class="text-gray-400">20mb</div>

                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-abstract-26 fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">Task
                                                <a href="#" class="text-primary fw-bold me-1">#45890</a>merged with
                                                <a href="#" class="text-primary fw-bold me-1">#45890</a>in “Ads Pro Admin Dashboard project:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Initiated at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Nina Nilson" data-bs-original-title="Nina Nilson" data-kt-initialized="1">
                                                    <img src="assets/media/avatars/300-14.jpg" alt="img" />
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-pencil fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">3 new application design concepts added:</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Created at 4:23 PM by</div>


                                                <div class="symbol symbol-circle symbol-25px" data-bs-toggle="tooltip" data-bs-boundary="window" data-bs-placement="top" aria-label="Marcus Dotson" data-bs-original-title="Marcus Dotson" data-kt-initialized="1">
                                                    <img src={User} alt="img" />
                                                </div>

                                            </div>

                                        </div>


                                        <div class="overflow-auto pb-5">
                                            <div class="d-flex align-items-center border border-dashed border-gray-300 rounded min-w-700px p-7">

                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-29.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay me-10">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-31.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>


                                                <div class="overlay">

                                                    <div class="overlay-wrapper">
                                                        <img alt="img" class="rounded w-150px" src="assets/media/stock/600x400/img-40.jpg" />
                                                    </div>


                                                    <div class="overlay-layer bg-dark bg-opacity-10 rounded">
                                                        <a href="#" class="btn btn-sm btn-primary btn-shadow">Explore</a>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-sms fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mb-10 mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New case
                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>#67890</a>is assigned to you in Multi-platform Database Design project</div>


                                            <div class="overflow-auto pb-5">

                                                <div class="d-flex align-items-center mt-1 fs-6">

                                                    <div class="text-muted me-2 fs-7">Added at 4:23 PM by</div>


                                                    <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>Alice Tan</a>

                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                </div>


                                <div class="timeline-item">

                                    <div class="timeline-line w-40px"></div>


                                    <div class="timeline-icon symbol symbol-circle symbol-40px">
                                        <div class="symbol-label bg-light">
                                            <i class="ki-outline ki-basket fs-2 text-gray-500"></i>
                                        </div>
                                    </div>


                                    <div class="timeline-content mt-n1">

                                        <div class="pe-3 mb-5">

                                            <div class="fs-5 fw-semibold mb-2">New order
                                                <a href="#" class="text-primary fw-bold me-1">#67890</a>is placed for Workshow Planning &amp; Budget Estimation</div>


                                            <div class="d-flex align-items-center mt-1 fs-6">

                                                <div class="text-muted me-2 fs-7">Placed at 4:23 PM by</div>


                                                <a href="#" class="fw-bold me-1" style={{color: "#8e736a"}}>Jimmy Bold</a>

                                            </div>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </div>
    )
}
