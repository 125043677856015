import React, { useEffect, useState } from 'react';
import Layout from '../../components/layout/layout';
import User from '../../assets/images/user.jpg';

export default function Investissement() {

  useEffect(() => {
    setToggleListCard(true);
  }, []);

  const [toggleList, setToggleList] = useState(false);
  const [toggleCardList, setToggleListCard] = useState(false);


  const handleList = () => {
    setToggleList(true);
    setToggleListCard(false);
  }
  const handleCardList = () => {
    setToggleListCard(true);
    setToggleList(false);
  }

  return (
    <Layout openDrawer={true}>
      <div style={{ padding: "1rem" }}>
        <div class="d-flex flex-wrap flex-stack pt-10 pb-8" data-select2-id="select2-data-138-oivu">


          <h3 class="fw-bold my-2">Project Targets

          </h3>

          <div class="d-flex flex-wrap my-1" data-select2-id="select2-data-137-f5uq">

            <ul class="nav nav-pills me-5" role="tablist">
              <li class="nav-item m-0" role="presentation">
                <span class="btn btn-sm btn-icon me-3" style={{backgroundColor: toggleCardList ? '#8e736a' : ''}} data-bs-toggle="tab" aria-selected="true" role="tab" onClick={() => handleCardList()}>
                  <i class="ki-outline ki-element-plus fs-1" style={{color: toggleCardList ? "#fff" : ""}}></i>
                </span>
              </li>
              <li class="nav-item m-0" role="presentation">
                <span class="btn btn-sm btn-icon" style={{backgroundColor: toggleList ? '#8e736a' : ''}} data-bs-toggle="tab" aria-selected="false" role="tab" tabindex="-1" onClick={() => handleList()}>
                  <i class="ki-outline ki-row-horizontal fs-2" style={{color: toggleList ? "#fff" : ""}}></i>
                </span>
              </li>
            </ul>

            <div class="my-0" data-select2-id="select2-data-136-004r">

              <select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-sm border-body bg-body w-150px select2-hidden-accessible" data-select2-id="select2-data-3-3taw" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                <option value="1" selected="selected" data-select2-id="select2-data-5-uyvj">Recently Updated</option>
                <option value="2" data-select2-id="select2-data-140-egjo">Last Month</option>
                <option value="3" data-select2-id="select2-data-141-eg75">Last Quarter</option>
                <option value="4" data-select2-id="select2-data-142-8vvr">Last Year</option>
              </select><span class="select2 select2-container select2-container--bootstrap5 select2-container--below" dir="ltr" data-select2-id="select2-data-4-l4rj" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-sm border-body bg-body w-150px" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-status-7n-container" aria-controls="select2-status-7n-container"><span class="select2-selection__rendered" id="select2-status-7n-container" role="textbox" aria-readonly="true" title="Recently Updated">Recently Updated</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

            </div>

          </div>

        </div>
        <div class="tab-content">

          <div id="kt_project_targets_card_pane" class={`tab-pane ${toggleCardList ? "active" : ''}`} role="tabpanel">

            <div class="row g-9">

              <div class="col-md-4 col-lg-12 col-xl-4">

                <div class="mb-9">
                  <div class="d-flex flex-stack">
                    <div class="fw-bold fs-4">Pas encore commencé
                      <span class="fs-6 text-gray-400 ms-2">2</span></div>

                    <div>
                      <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <i class="ki-outline ki-element-plus fs-2"></i>
                      </button>

                      <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c7901464">

                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bold">Filter Options</div>
                        </div>


                        <div class="separator border-gray-200"></div>


                        <div class="px-7 py-5">

                          <div class="mb-10">

                            <label class="form-label fw-semibold">Status:</label>


                            <div>
                              <select class="form-select form-select-solid select2-hidden-accessible" multiple="" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c7901464" data-allow-clear="true" data-select2-id="select2-data-6-p5qe" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                                <option></option>
                                <option value="1">Approved</option>
                                <option value="2">Pending</option>
                                <option value="2">In Process</option>
                                <option value="2">Rejected</option>
                              </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-7-q4gd" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--multiple form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false"><ul class="select2-selection__rendered" id="select2-utfc-container"></ul><span class="select2-search select2-search--inline"><textarea class="select2-search__field" type="search" tabindex="0" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" autocomplete="off" aria-label="Search" aria-describedby="select2-utfc-container" placeholder="Select option" style={{ width: "100%" }}></textarea></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                            </div>

                          </div>


                          <div class="mb-10">

                            <label class="form-label fw-semibold">Member Type:</label>


                            <div class="d-flex">

                              <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                <input class="form-check-input" type="checkbox" value="1" />
                                <span class="form-check-label">Author</span>
                              </label>


                              <label class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                <span class="form-check-label">Customer</span>
                              </label>

                            </div>

                          </div>


                          <div class="mb-10">

                            <label class="form-label fw-semibold">Notifications:</label>


                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                              <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                              <label class="form-check-label">Enabled</label>
                            </div>

                          </div>


                          <div class="d-flex justify-content-end">
                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>
                  <div class="h-3px w-100 bg-warning"></div>
                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">UI Design</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">Meeting with customer</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer – the entire process writing a blog post often takes a couple of hours if you can type</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Harry Mcpherson" data-bs-original-title="Harry Mcpherson" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                          <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">7</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">8</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">Phase 2.6 QA</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">User Module Testing</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer – the entire process writing a blog post often.</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Alan Warden" data-kt-initialized="1">
                          <span class="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Perry Matthew" data-kt-initialized="1">
                          <span class="symbol-label bg-success text-inverse-success fw-bold">R</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">8</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">10</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>

                {/* <a href="#" class="btn btn-primary er w-100 fs-6 px-8 py-4" data-bs-toggle="modal" data-bs-target="#kt_modal_new_target">Create New Target</a> */}
              </div>


              <div class="col-md-4 col-lg-12 col-xl-4">

                <div class="mb-9">
                  <div class="d-flex flex-stack">
                    <div class="fw-bold fs-4">En cours
                      <span class="fs-6 text-gray-400 ms-2">4</span></div>

                    <div>
                      <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <i class="ki-outline ki-element-plus fs-2"></i>
                      </button>

                      <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c7901529">

                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bold">Filter Options</div>
                        </div>


                        <div class="separator border-gray-200"></div>


                        <div class="px-7 py-5">

                          <div class="mb-10">

                            <label class="form-label fw-semibold">Status:</label>


                            <div>
                              <select class="form-select form-select-solid select2-hidden-accessible" multiple="" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c7901529" data-allow-clear="true" data-select2-id="select2-data-8-9w33" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                                <option></option>
                                <option value="1">Approved</option>
                                <option value="2">Pending</option>
                                <option value="2">In Process</option>
                                <option value="2">Rejected</option>
                              </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-9-c7pl" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--multiple form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false"><ul class="select2-selection__rendered" id="select2-s8qb-container"></ul><span class="select2-search select2-search--inline"><textarea class="select2-search__field" type="search" tabindex="0" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" autocomplete="off" aria-label="Search" aria-describedby="select2-s8qb-container" placeholder="Select option" style={{ width: "100%" }}></textarea></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                            </div>

                          </div>


                          <div class="mb-10">

                            <label class="form-label fw-semibold">Member Type:</label>


                            <div class="d-flex">

                              <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                <input class="form-check-input" type="checkbox" value="1" />
                                <span class="form-check-label">Author</span>
                              </label>


                              <label class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                <span class="form-check-label">Customer</span>
                              </label>

                            </div>

                          </div>


                          <div class="mb-10">

                            <label class="form-label fw-semibold">Notifications:</label>


                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                              <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                              <label class="form-check-label">Enabled</label>
                            </div>

                          </div>


                          <div class="d-flex justify-content-end">
                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>
                  <div class="h-3px w-100 bg-primary"></div>
                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">Development</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true" >

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">Sales report page</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer takes a couple hours</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Alan Warden" data-kt-initialized="1">
                          <span class="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Michelle Swanston" data-bs-original-title="Michelle Swanston" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">4</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">3</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">Testing</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">Meeting with customer</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer – the entire process writing a blog post often takes a couple of hours if you can type</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Francis Mitcham" data-bs-original-title="Francis Mitcham" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Harry Mcpherson" data-bs-original-title="Harry Mcpherson" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                          <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">6</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">9</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">UI Design</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">Design main Dashboard</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer takes a couple hours</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Francis Mitcham" data-bs-original-title="Francis Mitcham" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Michelle Swanston" data-bs-original-title="Michelle Swanston" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                          <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">4</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">2</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">Phase 2.6 QA</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">User Module Testing</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer – the entire process writing a blog post often.</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Barry Walter" data-bs-original-title="Barry Walter" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">2</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">4</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>


              <div class="col-md-4 col-lg-12 col-xl-4">

                <div class="mb-9">
                  <div class="d-flex flex-stack">
                    <div class="fw-bold fs-4">Terminée
                      <span class="fs-6 text-gray-400 ms-2">3</span></div>

                    <div>
                      <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                        <i class="ki-outline ki-element-plus fs-2"></i>
                      </button>

                      <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c79015b9" >

                        <div class="px-7 py-5">
                          <div class="fs-5 text-dark fw-bold">Filter Options</div>
                        </div>


                        <div class="separator border-gray-200"></div>


                        <div class="px-7 py-5">

                          <div class="mb-10">

                            <label class="form-label fw-semibold">Status:</label>


                            <div>
                              <select class="form-select form-select-solid select2-hidden-accessible" multiple="" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c79015b9" data-allow-clear="true" data-select2-id="select2-data-10-09zf" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                                <option></option>
                                <option value="1">Approved</option>
                                <option value="2">Pending</option>
                                <option value="2">In Process</option>
                                <option value="2">Rejected</option>
                              </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-11-tuk9" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--multiple form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="-1" aria-disabled="false"><ul class="select2-selection__rendered" id="select2-keb0-container"></ul><span class="select2-search select2-search--inline"><textarea class="select2-search__field" type="search" tabindex="0" autocorrect="off" autocapitalize="none" spellcheck="false" role="searchbox" aria-autocomplete="list" autocomplete="off" aria-label="Search" aria-describedby="select2-keb0-container" placeholder="Select option" style={{ width: "100%" }}></textarea></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                            </div>

                          </div>


                          <div class="mb-10">

                            <label class="form-label fw-semibold">Member Type:</label>


                            <div class="d-flex">

                              <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                <input class="form-check-input" type="checkbox" value="1" />
                                <span class="form-check-label">Author</span>
                              </label>


                              <label class="form-check form-check-sm form-check-custom form-check-solid">
                                <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                <span class="form-check-label">Customer</span>
                              </label>

                            </div>

                          </div>


                          <div class="mb-10">

                            <label class="form-label fw-semibold">Notifications:</label>


                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                              <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                              <label class="form-check-label">Enabled</label>
                            </div>

                          </div>


                          <div class="d-flex justify-content-end">
                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                          </div>

                        </div>

                      </div>

                    </div>

                  </div>
                  <div class="h-3px w-100 bg-success"></div>
                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">UI Design</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">Branding Logo</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer – the entire process writing a blog post often takes a couple of hours if you can type</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Perry Matthew" data-kt-initialized="1">
                          <span class="symbol-label bg-success text-inverse-success fw-bold">R</span>
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Barry Walter" data-bs-original-title="Barry Walter" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                          <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">7</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">9</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">QA</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">To check User Management</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer takes a couple hours</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Perry Matthew" data-kt-initialized="1">
                          <span class="symbol-label bg-info text-inverse-info fw-bold">P</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">9</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">1</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>


                <div class="card mb-6 mb-xl-9">

                  <div class="card-body">

                    <div class="d-flex flex-stack mb-3">

                      <div class="badge badge-light">Phase 2.6 QA</div>


                      <div>
                        <button type="button" class="btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                          <i class="ki-outline ki-element-plus fs-2"></i>
                        </button>

                        <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-3" data-kt-menu="true">

                          <div class="menu-item px-3">
                            <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">Payments</div>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Create Invoice</a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link flex-stack px-3">Create Payment
                              <span class="ms-2" data-bs-toggle="tooltip" aria-label="Specify a target name for future usage and reference" data-bs-original-title="Specify a target name for future usage and reference" data-kt-initialized="1">
                                <i class="ki-outline ki-information fs-6"></i>
                              </span></a>
                          </div>


                          <div class="menu-item px-3">
                            <a href="#" class="menu-link px-3">Generate Bill</a>
                          </div>


                          <div class="menu-item px-3" data-kt-menu-trigger="hover" data-kt-menu-placement="right-end">
                            <a href="#" class="menu-link px-3">
                              <span class="menu-title">Subscription</span>
                              <span class="menu-arrow"></span>
                            </a>

                            <div class="menu-sub menu-sub-dropdown w-175px py-4">

                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Plans</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Billing</a>
                              </div>


                              <div class="menu-item px-3">
                                <a href="#" class="menu-link px-3">Statements</a>
                              </div>


                              <div class="separator my-2"></div>


                              <div class="menu-item px-3">
                                <div class="menu-content px-3">

                                  <label class="form-check form-switch form-check-custom form-check-solid">

                                    <input class="form-check-input w-30px h-20px" type="checkbox" value="1" checked="checked" name="notifications" />


                                    <span class="form-check-label text-muted fs-6">Recuring</span>

                                  </label>

                                </div>
                              </div>

                            </div>

                          </div>


                          <div class="menu-item px-3 my-1">
                            <a href="#" class="menu-link px-3">Settings</a>
                          </div>

                        </div>

                      </div>

                    </div>


                    <div class="mb-2">
                      <a href="#" class="fs-4 fw-bold mb-1 text-gray-900 text-hover-primary">User Module Testing</a>
                    </div>


                    <div class="fs-6 fw-semibold text-gray-600 mb-5">First, a disclaimer – the entire process writing a blog post often.</div>


                    <div class="d-flex flex-stack flex-wrapr">

                      <div class="symbol-group symbol-hover my-1">
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Perry Matthew" data-kt-initialized="1">
                          <span class="symbol-label bg-success text-inverse-success fw-bold">R</span>
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                          <img alt="Pic" src={User} />
                        </div>
                        <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                          <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                        </div>
                      </div>


                      <div class="d-flex my-1">

                        <div class="border border-dashed border-gray-300 rounded d-flex align-items-center py-2 px-3">
                          <i class="ki-outline ki-paper-clip fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">8</span>
                        </div>


                        <div class="border border-dashed border-gray-300 d-flex align-items-center rounded py-2 px-3 ms-3">
                          <i class="ki-outline ki-message-text-2 fs-3"></i>
                          <span class="ms-1 fs-7 fw-bold text-gray-600">5</span>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>


          <div id="kt_project_targets_table_pane" class={`tab-pane ${toggleList ? "active" : ''}`} role="tabpanel">
            <div class="card card-flush">
              <div class="card-body pt-3">

                <div id="kt_profile_overview_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer">
                  <div class="table-responsive">
                    <table id="kt_profile_overview_table" class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold dataTable no-footer">
                      <thead class="fs-7 text-gray-400 text-uppercase">
                        <tr><th class="min-w-250px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Target: activate to sort column ascending">Target</th><th class="min-w-90px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Section: activate to sort column ascending">Section</th><th class="min-w-150px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Due Date: activate to sort column ascending">Due Date</th><th class="min-w-90px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Members: activate to sort column ascending">Members</th><th class="min-w-90px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label="Status: activate to sort column ascending">Status</th><th class="min-w-50px sorting" tabindex="0" aria-controls="kt_profile_overview_table" rowspan="1" colspan="1" style={{ width: "0px" }} aria-label=": activate to sort column ascending"></th></tr>
                      </thead>
                      <tbody class="fs-6">

                        <tr class="odd">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">Meeting with customer</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">UI Design</span>
                          </td>
                          <td>Nov 8, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="John Mixin" data-bs-original-title="John Mixin" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Susan Redwood" data-kt-initialized="1">
                                <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light-primary fw-bold me-auto">In Progress</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="even">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">User Module Testing</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">Phase 2.6 QA</span>
                          </td>
                          <td>Dec 4, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Alan Warden" data-kt-initialized="1">
                                <span class="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Robin Watterman" data-kt-initialized="1">
                                <span class="symbol-label bg-success text-inverse-success fw-bold">R</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light-success fw-bold me-auto">Completed</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="odd">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">Sales report page</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">QA</span>
                          </td>
                          <td>Aug 7, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Kristen Goodwin" data-bs-original-title="Kristen Goodwin" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Mikaela Collins" data-kt-initialized="1">
                                <span class="symbol-label bg-info text-inverse-info fw-bold">M</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light fw-bold me-auto">Yet to start</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="even">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">Meeting with customer</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">Prototype</span>
                          </td>
                          <td>Jul 12, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Robin Watterman" data-kt-initialized="1">
                                <span class="symbol-label bg-success text-inverse-success fw-bold">R</span>
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Brian Cox" data-bs-original-title="Brian Cox" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light-success fw-bold me-auto">Completed</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="odd">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">Design main Dashboard</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">UI Design</span>
                          </td>
                          <td>Apr 15, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Melody Macy" data-bs-original-title="Melody Macy" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Emma Smith" data-bs-original-title="Emma Smith" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Lucy Matthews" data-bs-original-title="Lucy Matthews" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light-success fw-bold me-auto">Completed</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="even">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">User Module Testing</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">Development</span>
                          </td>
                          <td>Mar 28, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Francis Mitcham" data-bs-original-title="Francis Mitcham" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Deanna Taylor" data-bs-original-title="Deanna Taylor" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Mikaela Collins" data-kt-initialized="1">
                                <span class="symbol-label bg-info text-inverse-info fw-bold">M</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light-primary fw-bold me-auto">In Progress</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="odd">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">To check User Management</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">Pahse 3.2</span>
                          </td>
                          <td>Oct 1, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Lucy Matthews" data-bs-original-title="Lucy Matthews" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Kristen Goodwin" data-bs-original-title="Kristen Goodwin" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Michelle Swanston" data-bs-original-title="Michelle Swanston" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light fw-bold me-auto">Yet to start</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr><tr class="even">
                          <td class="fw-bold">
                            <a href="#" class="text-gray-900 text-hover-primary">Create Roles Module</a>
                          </td>
                          <td data-order="Invalid date">
                            <span class="badge badge-light fw-semibold me-auto">Branding</span>
                          </td>
                          <td>Aug 5, 2020</td>
                          <td>
                            <div class="symbol-group symbol-hover fs-8">
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" aria-label="Michelle Swanston" data-bs-original-title="Michelle Swanston" data-kt-initialized="1">
                                <img alt="Pic" src={User} />
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Robin Watterman" data-kt-initialized="1">
                                <span class="symbol-label bg-success text-inverse-success fw-bold">R</span>
                              </div>
                              <div class="symbol symbol-25px symbol-circle" data-bs-toggle="tooltip" data-bs-original-title="Alan Warden" data-kt-initialized="1">
                                <span class="symbol-label bg-warning text-inverse-warning fw-bold">A</span>
                              </div>
                            </div>
                          </td>
                          <td>
                            <span class="badge badge-light fw-bold me-auto">Yet to start</span>
                          </td>
                          <td class="text-end">
                            <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">View</a>
                          </td>
                        </tr></tbody>
                    </table></div><div class="row"><div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div><div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"></div></div></div>

              </div>
            </div>
          </div>

        </div>
      </div>
    </Layout>
  )
}
