import React from 'react';
import Layout from '../../components/layout/layout';

export default function SettingsPreference() {
  return (
    <Layout openDrawer={true}>
      <div style={{padding: '2rem'}}>
        <div class="card card-flush">

          <div class="card-header pt-8">

            <div class="card-title">
              <h2>Preferences</h2>
            </div>

          </div>


          <div class="card-body">

            <form class="form" id="kt_file_manager_settings">

              <div class="fv-row row mb-15">

                <div class="col-md-3 d-flex align-items-center">

                  <label class="fs-6 fw-semibold">Langue</label>

                </div>


                <div class="col-md-9">

                  <select name="language" aria-label="Select a Language" data-control="select2" data-placeholder="Select a Language..." class="form-select mb-2 select2-hidden-accessible" data-select2-id="select2-data-3-xme3" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                    <option data-select2-id="select2-data-5-gqwd"></option>
                    <option value="id">Bahasa Indonesia - Indonesian</option>
                    <option value="msa">Bahasa Melayu - Malay</option>
                    <option value="ca">Català - Catalan</option>
                    <option value="cs">Čeština - Czech</option>
                    <option value="da">Dansk - Danish</option>
                    <option value="de">Deutsch - German</option>
                    <option value="en">English</option>
                    <option value="en-gb">English UK - British English</option>
                    <option value="es">Español - Spanish</option>
                    <option value="fil">Filipino</option>
                    <option value="fr">Français - French</option>
                    <option value="ga">Gaeilge - Irish (beta)</option>
                    <option value="gl">Galego - Galician (beta)</option>
                    <option value="hr">Hrvatski - Croatian</option>
                    <option value="it">Italiano - Italian</option>
                    <option value="hu">Magyar - Hungarian</option>
                    <option value="nl">Nederlands - Dutch</option>
                    <option value="no">Norsk - Norwegian</option>
                    <option value="pl">Polski - Polish</option>
                    <option value="pt">Português - Portuguese</option>
                    <option value="ro">Română - Romanian</option>
                    <option value="sk">Slovenčina - Slovak</option>
                    <option value="fi">Suomi - Finnish</option>
                    <option value="sv">Svenska - Swedish</option>
                    <option value="vi">Tiếng Việt - Vietnamese</option>
                    <option value="tr">Türkçe - Turkish</option>
                    <option value="el">Ελληνικά - Greek</option>
                    <option value="bg">Български език - Bulgarian</option>
                    <option value="ru">Русский - Russian</option>
                    <option value="sr">Српски - Serbian</option>
                    <option value="uk">Українська мова - Ukrainian</option>
                    <option value="he">עִבְרִית - Hebrew</option>
                    <option value="ur">اردو - Urdu (beta)</option>
                    <option value="ar">العربية - Arabic</option>
                    <option value="fa">فارسی - Persian</option>
                    <option value="mr">मराठी - Marathi</option>
                    <option value="hi">हिन्दी - Hindi</option>
                    <option value="bn">বাংলা - Bangla</option>
                    <option value="gu">ગુજરાતી - Gujarati</option>
                    <option value="ta">தமிழ் - Tamil</option>
                    <option value="kn">ಕನ್ನಡ - Kannada</option>
                    <option value="th">ภาษาไทย - Thai</option>
                    <option value="ko">한국어 - Korean</option>
                    <option value="ja">日本語 - Japanese</option>
                    <option value="zh-cn">简体中文 - Simplified Chinese</option>
                    <option value="zh-tw">繁體中文 - Traditional Chinese</option>
                  </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-4-ddxu" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select mb-2" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-language-1i-container" aria-controls="select2-language-1i-container"><span class="select2-selection__rendered" id="select2-language-1i-container" role="textbox" aria-readonly="true" title="Select a Language..."><span class="select2-selection__placeholder">Select a Language...</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

                </div>

              </div>


              <div class="fv-row row mb-15">

                <div class="col-md-3">

                  <label class="fs-6 fw-semibold mt-2">Format de date</label>
                  <div class="text-muted fs-7">For more info on moment() date formats, please
                    <a href="https://momentjs.com/docs/#/displaying/format/" target="_blank">click here</a>.</div>

                </div>


                <div class="col-md-9">

                  <select name="dateformat" aria-label="Select a date format" data-hide-search="true" data-control="select2" data-placeholder="Select a date format..." class="form-select mb-2 select2-hidden-accessible" data-select2-id="select2-data-6-4d4l" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                    <option data-select2-id="select2-data-8-2iha"></option>
                    <option value="1">10 Sep 2021, 10:15 AM</option>
                    <option value="2">10/09/2021, 10:15 AM</option>
                    <option value="3">09-10-2021</option>
                    <option value="4">Sunday, September 10th 2010</option>
                  </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-7-qmk4" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select mb-2" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-dateformat-16-container" aria-controls="select2-dateformat-16-container"><span class="select2-selection__rendered" id="select2-dateformat-16-container" role="textbox" aria-readonly="true" title="Select a date format..."><span class="select2-selection__placeholder">Select a date format...</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

                </div>

              </div>


              <div class="fv-row row mb-15">

                <div class="col-md-3 d-flex align-items-center">

                  <label class="fs-6 fw-semibold">Automatic time zone</label>

                </div>


                <div class="col-md-9">

                  <div class="form-check form-switch form-check-custom form-check-solid me-10">
                    <input class="form-check-input h-30px w-50px" name="autotimezone" type="checkbox" value="" id="autotimezone" />
                    <label class="form-check-label" for="autotimezone">GMT +10:00</label>
                  </div>

                </div>

              </div>


              <div class="fv-row row mb-15">

                <div class="col-md-3">

                  <label class="fs-6 fw-semibold mt-2">Folder Compression</label>
                  <div class="text-muted fs-7">Default file compression type when downloading folders</div>

                </div>


                <div class="col-md-9">

                  <select name="compression" aria-label="Select a Compressions type" data-control="select2" data-hide-search="true" data-placeholder="Select a Compressions type..." class="form-select mb-2 select2-hidden-accessible" data-select2-id="select2-data-9-w8gn" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                    <option data-select2-id="select2-data-11-t3qv"></option>
                    <option value="1">*.zip</option>
                    <option value="2">*.7z</option>
                    <option value="3">*.rar</option>
                    <option value="4">*.tar.gz</option>
                  </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-10-6po3" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select mb-2" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-compression-h5-container" aria-controls="select2-compression-h5-container"><span class="select2-selection__rendered" id="select2-compression-h5-container" role="textbox" aria-readonly="true" title="Select a Compressions type..."><span class="select2-selection__placeholder">Select a Compressions type...</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

                </div>

              </div>


              <div class="fv-row row mb-15">

                <div class="col-md-3">

                  <label class="fs-6 fw-semibold">Sharing Permissions</label>

                </div>


                <div class="col-md-9">

                  <label class="fs-6 fw-semibold mb-3">Organisation</label>


                  <div class="d-flex">

                    <div class="form-check form-switch form-check-custom form-check-solid me-10">
                      <input class="form-check-input h-30px w-50px" style={{backgroundColor: "#8e736a", color: "#fff"}} name="org_read" type="checkbox" value="" id="org_read" checked="checked" />
                      <label class="form-check-label" for="org_read">Read</label>
                    </div>


                    <div class="form-check form-switch form-check-custom form-check-solid me-10">
                      <input class="form-check-input h-30px w-50px" style={{backgroundColor: "#8e736a", color: "#fff"}} name="org_write" type="checkbox" value="" id="org_write" checked="checked" />
                      <label class="form-check-label" for="org_write">Write</label>
                    </div>

                  </div>


                  <div class="separator mt-6 mb-5"></div>


                  <label class="fs-6 fw-semibold mb-3">Public</label>


                  <div class="d-flex">

                    <div class="form-check form-switch form-check-custom form-check-solid me-10">
                      <input class="form-check-input h-30px w-50px" style={{backgroundColor: "#8e736a", color: "#fff"}} name="public_read" type="checkbox" value="" id="public_read" checked="checked" />
                      <label class="form-check-label" for="public_read">Read</label>
                    </div>


                    <div class="form-check form-switch form-check-custom form-check-solid me-10">
                      <input class="form-check-input h-30px w-50px"  name="public_write" type="checkbox" value="" id="public_write" />
                      <label class="form-check-label" for="public_write">Write</label>
                    </div>

                  </div>

                </div>

              </div>


              <div class="row mt-12">
                <div class="col-md-9 offset-md-3">

                  <button type="button" class="btn btn-light me-3">Cancel</button>


                  <button type="button" class="btn" style={{backgroundColor: "#8e736a", color: "#fff"}} id="kt_file_manager_settings_submit">
                    <span class="indicator-label">Sauvegarder</span>
                    <span class="indicator-progress">Please wait...
                      <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                  </button>

                </div>
              </div>

            </form>

          </div>

        </div>
      </div>
    </Layout>
  )
}
