import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setOpenUserDrawer } from '../../store/actions/layouts'
import { setOpenDrawer } from '../../store/actions/layouts';
// IMPORT COMPONENTS
import Navbar from '../../components/navbar/navbar';
import Header from '../../components/header/header';
import Footer from '../../components/footer/footer';
import Layout from '../../components/layout/layout';
// IMPORT IMAGES
import Logo from '../../assets/images/logo.png';

export default function Rapport() {

  return (
    <Layout openDrawer={true}>
      <div id="kt_app_content_container" class="app-container container-xxl">

        <div class="card card-flush">

          <div class="card-header align-items-center py-5 gap-2 gap-md-5">

            <div class="card-title">

              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-outline ki-magnifier fs-3 position-absolute ms-4"></i>
                <input type="text" data-kt-ecommerce-order-filter="search" class="form-control form-control-solid w-250px ps-12" placeholder="Rechercher un rapport" />
              </div>


              <div id="kt_ecommerce_report_customer_orders_export" class="d-none"><div class="dt-buttons btn-group flex-wrap">      <button class="btn btn-secondary buttons-copy buttons-html5" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" type="button"><span>Copy</span></button> <button class="btn btn-secondary buttons-excel buttons-html5" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" type="button"><span>Excel</span></button> <button class="btn btn-secondary buttons-csv buttons-html5" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" type="button"><span>CSV</span></button> <button class="btn btn-secondary buttons-pdf buttons-html5" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" type="button"><span>PDF</span></button> </div></div>

            </div>

            <div class="card-toolbar flex-row-fluid justify-content-end gap-5">

              <input class="form-control form-control-solid w-100 mw-250px" placeholder="Sélectionnez une période" id="kt_ecommerce_report_customer_orders_daterangepicker" />


              <div class="w-150px">

                <select class="form-select form-select-solid select2-hidden-accessible" data-control="select2" data-hide-search="true" data-placeholder="Status" data-kt-ecommerce-order-filter="status" data-select2-id="select2-data-3-lcdl" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                  <option data-select2-id="select2-data-5-fyt9"></option>
                  <option value="all">All</option>
                  <option value="active">Active</option>
                  <option value="locked">Locked</option>
                  <option value="disabled">Disabled</option>
                  <option value="banned">Banned</option>
                </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-4-zipr" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-tlj1-container" aria-controls="select2-tlj1-container"><span class="select2-selection__rendered" id="select2-tlj1-container" role="textbox" aria-readonly="true" title="Status"><span class="select2-selection__placeholder">Status</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

              </div>


              <button type="button" class="btn" style={{ backgroundColor: "#8e736a", color: "#fff" }} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                <i class="ki-outline ki-exit-up fs-2" style={{ color: '#fff' }}></i>Exporter un rapport</button>

              <div id="kt_ecommerce_report_customer_orders_export_menu" class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-200px py-4" data-kt-menu="true">

                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3" data-kt-ecommerce-export="copy">Copy to clipboard</a>
                </div>


                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3" data-kt-ecommerce-export="excel">Export as Excel</a>
                </div>


                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3" data-kt-ecommerce-export="csv">Export as CSV</a>
                </div>


                <div class="menu-item px-3">
                  <a href="#" class="menu-link px-3" data-kt-ecommerce-export="pdf">Export as PDF</a>
                </div>

              </div>


            </div>

          </div>


          <div class="card-body pt-0">

            <div id="kt_ecommerce_report_customer_orders_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="table-responsive"><table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_ecommerce_report_customer_orders_table">
              <thead>
                <tr class="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"><th class="min-w-100px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "146.467px" }} aria-label="Customer Name: activate to sort column ascending">Nom du client</th><th class="min-w-100px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "191.967px" }} aria-label="Email: activate to sort column ascending">Email</th><th class="min-w-100px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "124.317px" }} aria-label="Status: activate to sort column ascending">Status</th><th class="min-w-100px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "201.883px" }} aria-label="Date Joined: activate to sort column ascending">Date Joined</th><th class="text-end min-w-75px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "93.7667px" }} aria-label="No. Orders: activate to sort column ascending">No. Orders</th><th class="text-end min-w-75px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "116.283px" }} aria-label="No. Products: activate to sort column ascending">No. Products</th><th class="text-end min-w-100px sorting" tabindex="0" aria-controls="kt_ecommerce_report_customer_orders_table" rowspan="1" colspan="1" style={{ width: "124.317px" }} aria-label="Total: activate to sort column ascending">Total</th></tr>
              </thead>
              <tbody class="fw-semibold text-gray-600">

                <tr class="odd">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Emma Smith</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">smith@kpmg.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-03-10T10:30:00+00:00">10 Mar 2023, 10:30 am</td>
                  <td class="text-end pe-0">65</td>
                  <td class="text-end pe-0">71</td>
                  <td class="text-end">$1401.00</td>
                </tr><tr class="even">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Melody Macy</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">melody@altbox.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-06-20T18:05:00+00:00">20 Jun 2023, 6:05 pm</td>
                  <td class="text-end pe-0">36</td>
                  <td class="text-end pe-0">51</td>
                  <td class="text-end">$1372.00</td>
                </tr><tr class="odd">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Max Smith</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">max@kt.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-07-25T11:05:00+00:00">25 Jul 2023, 11:05 am</td>
                  <td class="text-end pe-0">47</td>
                  <td class="text-end pe-0">58</td>
                  <td class="text-end">$2039.00</td>
                </tr><tr class="even">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Sean Bean</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">sean@dellito.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-06-24T14:40:00+00:00">24 Jun 2023, 2:40 pm</td>
                  <td class="text-end pe-0">99</td>
                  <td class="text-end pe-0">111</td>
                  <td class="text-end">$560.00</td>
                </tr><tr class="odd">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Brian Cox</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">brian@exchange.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-05-05T18:05:00+00:00">05 May 2023, 6:05 pm</td>
                  <td class="text-end pe-0">91</td>
                  <td class="text-end pe-0">106</td>
                  <td class="text-end">$3420.00</td>
                </tr><tr class="even">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Mikaela Collins</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">mik@pex.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-07-25T10:30:00+00:00">25 Jul 2023, 10:30 am</td>
                  <td class="text-end pe-0">81</td>
                  <td class="text-end pe-0">91</td>
                  <td class="text-end">$1871.00</td>
                </tr><tr class="odd">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Francis Mitcham</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">f.mit@kpmg.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-danger">Banned</div>
                  </td>
                  <td data-order="2023-02-21T17:20:00+00:00">21 Feb 2023, 5:20 pm</td>
                  <td class="text-end pe-0">94</td>
                  <td class="text-end pe-0">104</td>
                  <td class="text-end">$3401.00</td>
                </tr><tr class="even">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Olivia Wild</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">olivia@corpmail.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-06-20T18:05:00+00:00">20 Jun 2023, 6:05 pm</td>
                  <td class="text-end pe-0">73</td>
                  <td class="text-end pe-0">87</td>
                  <td class="text-end">$1647.00</td>
                </tr><tr class="odd">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Neil Owen</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">owen.neil@gmail.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-success">Active</div>
                  </td>
                  <td data-order="2023-07-25T17:30:00+00:00">25 Jul 2023, 5:30 pm</td>
                  <td class="text-end pe-0">77</td>
                  <td class="text-end pe-0">92</td>
                  <td class="text-end">$3980.00</td>
                </tr><tr class="even">
                  <td>
                    <a href="../../demo29/dist/apps/ecommerce/customers/details.html" class="text-dark text-hover-primary">Dan Wilson</a>
                  </td>
                  <td>
                    <a href="#" class="text-dark text-hover-primary">dam@consilting.com</a>
                  </td>
                  <td>
                    <div class="badge badge-light-warning">Locked</div>
                  </td>
                  <td data-order="2023-02-21T21:23:00+00:00">21 Feb 2023, 9:23 pm</td>
                  <td class="text-end pe-0">73</td>
                  <td class="text-end pe-0">88</td>
                  <td class="text-end">$1392.00</td>
                </tr></tbody>
            </table>
            </div>
              <div class="row">
                <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
                  <div class="dataTables_length" id="kt_ecommerce_report_customer_orders_table_length">
                    <label>
                      <select name="kt_ecommerce_report_customer_orders_table_length" aria-controls="kt_ecommerce_report_customer_orders_table" class="form-select form-select-sm form-select-solid">
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                      </select>
                    </label>
                  </div>
                </div>
                <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                  <div class="dataTables_paginate paging_simple_numbers" id="kt_ecommerce_report_customer_orders_table_paginate">
                    <ul class="pagination">
                      <li class="paginate_button page-item previous disabled" id="kt_ecommerce_report_customer_orders_table_previous">
                        <a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="0" tabindex="0" class="page-link">
                          <i class="previous"></i>
                        </a>
                      </li>
                      <li class="paginate_button page-item" style={{ backgroundColor: "#8e736a", color: "#fff" }}>
                        <a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="1" tabindex="0" class="page-link" style={{ color: "#fff" }}>1</a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="3" tabindex="0" class="page-link">3</a>
                      </li>
                      <li class="paginate_button page-item ">
                        <a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="4" tabindex="0" class="page-link">4</a></li><li class="paginate_button page-item "><a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="5" tabindex="0" class="page-link">5</a></li><li class="paginate_button page-item next" id="kt_ecommerce_report_customer_orders_table_next"><a href="#" aria-controls="kt_ecommerce_report_customer_orders_table" data-dt-idx="6" tabindex="0" class="page-link"><i class="next"></i></a></li></ul></div></div></div></div>

          </div>

        </div>

      </div>
    </Layout>
  )
}
