import React from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import User from '../../assets/images/user.jpg';

export default function SettingsRoleDetail() {
    return (
        <Layout openDrawer={true}>
            <div id="kt_app_content" class="app-content flex-column-fluid">
                <div id="kt_app_content_container" class="app-container container-xxl">
                    <div class="me-2 mb-3">
                        <Link to="/setting-role" class="btn btn-icon bg-light rounded-circle">
                            <i class="ki-outline ki-black-left fs-2 text-gray-800"></i>
                        </Link>
                    </div>

                    <div class="d-flex flex-column flex-lg-row">

                        <div class="flex-column flex-lg-row-auto w-100 w-lg-200px w-xl-300px mb-10">

                            <div class="card card-flush">

                                <div class="card-header">

                                    <div class="card-title">
                                        <h2 class="mb-0">Developer</h2>
                                    </div>

                                </div>


                                <div class="card-body pt-0">

                                    <div class="d-flex flex-column text-gray-600">
                                        <div class="d-flex align-items-center py-2">
                                            <span class="bullet bg-primary me-3"></span>Some Admin Controls</div>
                                        <div class="d-flex align-items-center py-2">
                                            <span class="bullet bg-primary me-3"></span>View Financial Summaries only</div>
                                        <div class="d-flex align-items-center py-2">
                                            <span class="bullet bg-primary me-3"></span>View and Edit API Controls</div>
                                        <div class="d-flex align-items-center py-2">
                                            <span class="bullet bg-primary me-3"></span>View Payouts only</div>
                                        <div class="d-flex align-items-center py-2">
                                            <span class="bullet bg-primary me-3"></span>View and Edit Disputes</div>
                                        <div class="d-flex align-items-center py-2 d-none">
                                            <span class="bullet bg-primary me-3"></span>
                                            <em>and 3 more...</em>
                                        </div>
                                    </div>

                                </div>


                                <div class="card-footer pt-0">
                                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#kt_modal_update_role" style={{backgroundColor: "#8e736a", color: "#fff"}}>Modifier Role</button>
                                </div>

                            </div>



                            <div class="modal fade" id="kt_modal_update_role" tabindex="-1" aria-hidden="true">

                                <div class="modal-dialog modal-dialog-centered mw-750px">

                                    <div class="modal-content">

                                        <div class="modal-header">

                                            <h2 class="fw-bold">Update Role</h2>


                                            <div class="btn btn-icon btn-sm btn-active-icon-primary" data-kt-roles-modal-action="close">
                                                <i class="ki-outline ki-cross fs-1"></i>
                                            </div>

                                        </div>


                                        <div class="modal-body scroll-y mx-5 my-7">

                                            <form id="kt_modal_update_role_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                                                <div class="d-flex flex-column scroll-y me-n7 pe-7" id="kt_modal_update_role_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_update_role_header" data-kt-scroll-wrappers="#kt_modal_update_role_scroll" data-kt-scroll-offset="300px" style={{ maxHeight: "477px" }}>

                                                    <div class="fv-row mb-10 fv-plugins-icon-container">

                                                        <label class="fs-5 fw-bold form-label mb-2">
                                                            <span class="required">Role name</span>
                                                        </label>


                                                        <input class="form-control form-control-solid" placeholder="Enter a role name" name="role_name" value="Developer" />

                                                        <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                                                    <div class="fv-row">

                                                        <label class="fs-5 fw-bold form-label mb-2">Role Permissions</label>


                                                        <div class="table-responsive">

                                                            <table class="table align-middle table-row-dashed fs-6 gy-5">

                                                                <tbody class="text-gray-600 fw-semibold">

                                                                    <tr>
                                                                        <td class="text-gray-800">Administrator Access
                                                                            <span class="ms-1" data-bs-toggle="tooltip" aria-label="Allows a full access to the system" data-bs-original-title="Allows a full access to the system" data-kt-initialized="1">
                                                                                <i class="ki-outline ki-information-5 text-gray-500 fs-6"></i>
                                                                            </span></td>
                                                                        <td>

                                                                            <label class="form-check form-check-sm form-check-custom form-check-solid me-9">
                                                                                <input class="form-check-input" type="checkbox" value="" id="kt_roles_select_all" />
                                                                                <span class="form-check-label" for="kt_roles_select_all">Select all</span>
                                                                            </label>

                                                                        </td>
                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">User Management</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="user_management_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="user_management_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="user_management_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Content Management</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="content_management_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="content_management_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="content_management_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Financial Management</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="financial_management_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="financial_management_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="financial_management_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Reporting</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="reporting_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="reporting_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="reporting_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Payroll</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="payroll_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="payroll_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="payroll_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Disputes Management</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="disputes_management_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="disputes_management_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="disputes_management_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">API Controls</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="api_controls_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="api_controls_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="api_controls_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Database Management</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="database_management_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="database_management_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="database_management_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>


                                                                    <tr>

                                                                        <td class="text-gray-800">Repository Management</td>


                                                                        <td>

                                                                            <div class="d-flex">

                                                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="repository_management_read" />
                                                                                    <span class="form-check-label">Read</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid me-5 me-lg-20">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="repository_management_write" />
                                                                                    <span class="form-check-label">Write</span>
                                                                                </label>


                                                                                <label class="form-check form-check-custom form-check-solid">
                                                                                    <input class="form-check-input" type="checkbox" value="" name="repository_management_create" />
                                                                                    <span class="form-check-label">Create</span>
                                                                                </label>

                                                                            </div>

                                                                        </td>

                                                                    </tr>

                                                                </tbody>

                                                            </table>

                                                        </div>

                                                    </div>

                                                </div>


                                                <div class="text-center pt-15">
                                                    <button type="reset" class="btn btn-light me-3" data-kt-roles-modal-action="cancel">Discard</button>
                                                    <button type="submit" class="btn btn-primary" data-kt-roles-modal-action="submit">
                                                        <span class="indicator-label">Submit</span>
                                                        <span class="indicator-progress">Please wait...
                                                            <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                                                    </button>
                                                </div>

                                            </form>

                                        </div>

                                    </div>

                                </div>

                            </div>


                        </div>


                        <div class="flex-lg-row-fluid ms-lg-10">

                            <div class="card card-flush mb-6 mb-xl-9">

                                <div class="card-header pt-5">

                                    <div class="card-title">
                                        <h2 class="d-flex align-items-center">Users Assigned
                                            <span class="text-gray-600 fs-6 ms-1">(14)</span></h2>
                                    </div>


                                    <div class="card-toolbar">

                                        <div class="d-flex align-items-center position-relative my-1" data-kt-view-roles-table-toolbar="base">
                                            <i class="ki-outline ki-magnifier fs-1 position-absolute ms-6"></i>
                                            <input type="text" data-kt-roles-table-filter="search" class="form-control form-control-solid w-250px ps-15" placeholder="Rechercher des utilisateurs" />
                                        </div>


                                        <div class="d-flex justify-content-end align-items-center d-none" data-kt-view-roles-table-toolbar="selected">
                                            <div class="fw-bold me-5">
                                                <span class="me-2" data-kt-view-roles-table-select="selected_count"></span>Selected</div>
                                            <button type="button" class="btn btn-danger" data-kt-view-roles-table-select="delete_selected">Delete Selected</button>
                                        </div>

                                    </div>

                                </div>


                                <div class="card-body pt-0">

                                    <div id="kt_roles_view_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="table-responsive"><table class="table align-middle table-row-dashed fs-6 gy-5 mb-0 dataTable no-footer" id="kt_roles_view_table">
                                        <thead>
                                            <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"><th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" style={{ width: "29.9px" }} aria-label="
																	
																		
																	
																">
                                                <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                                                    <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_roles_view_table .form-check-input" value="1" />
                                                </div>
                                            </th><th class="min-w-50px sorting" tabindex="0" aria-controls="kt_roles_view_table" rowspan="1" colspan="1" style={{ width: "73.0667px" }} aria-label="ID: activate to sort column ascending">ID</th><th class="min-w-150px sorting" tabindex="0" aria-controls="kt_roles_view_table" rowspan="1" colspan="1" style={{ width: "257.517px" }} aria-label="User: activate to sort column ascending">User</th><th class="min-w-125px sorting" tabindex="0" aria-controls="kt_roles_view_table" rowspan="1" colspan="1" style={{ width: "196.067px" }} aria-label="Joined Date: activate to sort column ascending">Joined Date</th><th class="text-end min-w-100px sorting_disabled" rowspan="1" colspan="1" style={{ width: "122.95px" }} aria-label="Actions">Actions</th></tr>
                                        </thead>
                                        <tbody class="fw-semibold text-gray-600">














                                            <tr class="odd">
                                                <td>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                    </div>
                                                </td>
                                                <td>ID4162</td>
                                                <td class="d-flex align-items-center">

                                                    <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html">
                                                            <div class="symbol-label">
                                                                <img src={User} alt="Emma Smith" class="w-100" />
                                                            </div>
                                                        </a>
                                                    </div>


                                                    <div class="d-flex flex-column">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">Emma Smith</a>
                                                        <span>smith@kpmg.com</span>
                                                    </div>

                                                </td>
                                                <td data-order="2023-06-24T22:10:00+00:00">24 Jun 2023, 10:10 pm</td>
                                                <td class="text-end">
                                                    <a href="#" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                                                        <i class="ki-outline ki-down fs-5 m-0"></i></a>

                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                                                        <div class="menu-item px-3">
                                                            <a href="../../demo29/dist/apps/user-management/users/view.html" class="menu-link px-3">View</a>
                                                        </div>


                                                        <div class="menu-item px-3">
                                                            <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row">Delete</a>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr><tr class="even">
                                                <td>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                    </div>
                                                </td>
                                                <td>ID1832</td>
                                                <td class="d-flex align-items-center">

                                                    <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html">
                                                            <div class="symbol-label fs-3 bg-light-danger text-danger">M</div>
                                                        </a>
                                                    </div>


                                                    <div class="d-flex flex-column">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">Melody Macy</a>
                                                        <span>melody@altbox.com</span>
                                                    </div>

                                                </td>
                                                <td data-order="2023-02-21T17:30:00+00:00">21 Feb 2023, 5:30 pm</td>
                                                <td class="text-end">
                                                    <a href="#" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                                                        <i class="ki-outline ki-down fs-5 m-0"></i></a>

                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                                                        <div class="menu-item px-3">
                                                            <a href="../../demo29/dist/apps/user-management/users/view.html" class="menu-link px-3">View</a>
                                                        </div>


                                                        <div class="menu-item px-3">
                                                            <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row">Delete</a>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr><tr class="odd">
                                                <td>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                    </div>
                                                </td>
                                                <td>ID1530</td>
                                                <td class="d-flex align-items-center">

                                                    <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html">
                                                            <div class="symbol-label">
                                                                <img src={User} alt="Max Smith" class="w-100" />
                                                            </div>
                                                        </a>
                                                    </div>


                                                    <div class="d-flex flex-column">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">Max Smith</a>
                                                        <span>max@kt.com</span>
                                                    </div>

                                                </td>
                                                <td data-order="2023-12-20T18:05:00+00:00">20 Dec 2023, 6:05 pm</td>
                                                <td class="text-end">
                                                    <a href="#" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                                                        <i class="ki-outline ki-down fs-5 m-0"></i></a>

                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                                                        <div class="menu-item px-3">
                                                            <a href="../../demo29/dist/apps/user-management/users/view.html" class="menu-link px-3">View</a>
                                                        </div>


                                                        <div class="menu-item px-3">
                                                            <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row">Delete</a>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr><tr class="even">
                                                <td>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                    </div>
                                                </td>
                                                <td>ID9773</td>
                                                <td class="d-flex align-items-center">

                                                    <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html">
                                                            <div class="symbol-label">
                                                                <img src={User} alt="Sean Bean" class="w-100" />
                                                            </div>
                                                        </a>
                                                    </div>


                                                    <div class="d-flex flex-column">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">Sean Bean</a>
                                                        <span>sean@dellito.com</span>
                                                    </div>

                                                </td>
                                                <td data-order="2023-02-21T22:10:00+00:00">21 Feb 2023, 10:10 pm</td>
                                                <td class="text-end">
                                                    <a href="#" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                                                        <i class="ki-outline ki-down fs-5 m-0"></i></a>

                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                                                        <div class="menu-item px-3">
                                                            <a href="../../demo29/dist/apps/user-management/users/view.html" class="menu-link px-3">View</a>
                                                        </div>


                                                        <div class="menu-item px-3">
                                                            <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row">Delete</a>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr><tr class="odd">
                                                <td>
                                                    <div class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                    </div>
                                                </td>
                                                <td>ID5192</td>
                                                <td class="d-flex align-items-center">

                                                    <div class="symbol symbol-circle symbol-50px overflow-hidden me-3">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html">
                                                            <div class="symbol-label">
                                                                <img src={User} alt="Brian Cox" class="w-100" />
                                                            </div>
                                                        </a>
                                                    </div>


                                                    <div class="d-flex flex-column">
                                                        <a href="../../demo29/dist/apps/user-management/users/view.html" class="text-gray-800 text-hover-primary mb-1">Brian Cox</a>
                                                        <span>brian@exchange.com</span>
                                                    </div>

                                                </td>
                                                <td data-order="2023-02-21T11:30:00+00:00">21 Feb 2023, 11:30 am</td>
                                                <td class="text-end">
                                                    <a href="#" class="btn btn-sm btn-light btn-active-light-primary" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Actions
                                                        <i class="ki-outline ki-down fs-5 m-0"></i></a>

                                                    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-semibold fs-7 w-125px py-4" data-kt-menu="true">

                                                        <div class="menu-item px-3">
                                                            <a href="../../demo29/dist/apps/user-management/users/view.html" class="menu-link px-3">View</a>
                                                        </div>


                                                        <div class="menu-item px-3">
                                                            <a href="#" class="menu-link px-3" data-kt-roles-table-filter="delete_row">Delete</a>
                                                        </div>

                                                    </div>

                                                </td>
                                            </tr></tbody>
                                    </table></div><div class="row"><div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div><div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end"><div class="dataTables_paginate paging_simple_numbers" id="kt_roles_view_table_paginate"><ul class="pagination"><li class="paginate_button page-item previous disabled" id="kt_roles_view_table_previous"><a href="#" aria-controls="kt_roles_view_table" data-dt-idx="0" tabindex="0" class="page-link"><i class="previous"></i></a></li><li class="paginate_button page-item " style={{backgroundColor: "#8e736a", color: "#fff"}}><a href="#" aria-controls="kt_roles_view_table" data-dt-idx="1" tabindex="0" class="page-link" style={{color: "#fff"}}>1</a></li><li class="paginate_button page-item "><a href="#" aria-controls="kt_roles_view_table" data-dt-idx="2" tabindex="0" class="page-link">2</a></li><li class="paginate_button page-item "><a href="#" aria-controls="kt_roles_view_table" data-dt-idx="3" tabindex="0" class="page-link">3</a></li><li class="paginate_button page-item next" id="kt_roles_view_table_next"><a href="#" aria-controls="kt_roles_view_table" data-dt-idx="4" tabindex="0" class="page-link"><i class="next"></i></a></li></ul></div></div></div></div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div>

            </div>
        </Layout>
    )
}
