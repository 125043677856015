import React from 'react';
import Pdf from "../../assets/images/pdf.png";

export default function ProjectFiles() {
    return (
        <div>


            <h3 class="fw-bold my-2">Project Files
                <span class="fs-6 text-gray-400 fw-semibold ms-1">+590</span>
            </h3>

            <div class="d-flex my-2">

                <div class="d-flex align-items-center position-relative me-4">
                    <i class="ki-outline ki-magnifier fs-3 position-absolute translate-middle-y top-50 ms-4"></i>
                    <input type="text" id="kt_filter_search" class="form-control form-control-sm form-control-solid bg-body fw-semibold fs-7 w-150px ps-11" placeholder="Search" />
                </div>

                <a href="../../demo29/dist/apps/file-manager/folders.html" class="btn btn-sm fw-bolder" style={{backgroundColor: "#8e736a", color:"#fff"}}>Rechercher</a>
            </div>


            <div className='row g-6 g-xl-9 mb-xl-9'>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/pdf-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">Project Reqs..</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">3 days ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/doc-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">CRM App Docs..</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">3 days ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/css-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">User CRUD Styles</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">4 days ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/ai-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">Product Logo</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">5 days ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/sql-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">Orders backup</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">1 week ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/xml-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">UTAIR CRM API Co..</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">2 weeks ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100">
                    <div class="card-body d-flex justify-content-center text-center flex-column p-8">
                        <a href="../../demo29/dist/apps/file-manager/files.html" class="text-gray-800 text-hover-primary d-flex flex-column">
                            <div class="symbol symbol-60px mb-5">
                                <img src={Pdf} class="theme-light-show" alt="" />
                                <img src="assets/media/svg/files/tif-dark.svg" class="theme-dark-show" alt="" />
                            </div>
                            <div class="fs-5 fw-bold mb-2">Tower Hill App..</div>
                        </a>
                        <div class="fs-7 fw-semibold text-gray-400">3 weeks ago</div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 col-xl-3">
                <div class="card h-100 flex-center border-primary border border-dashed p-8" style={{backgroundColor: '#8e736a'}}>
                    <img src="assets/media/svg/files/upload.svg" class="mb-5" alt="" />
                    <a href="#" class="fs-5 fw-bold mb-2" style={{color: "#fff"}}>Upload de fichier</a>
                    <div class="fs-7 fw-semibold text-gray-400">Drag and drop files here</div>
                </div>
            </div>
            </div>

        </div>
    )
}
