import React, { } from 'react';

// IMPORT CONFIG & DEPENDENCIES
import { Switch, Route, withRouter } from 'react-router-dom';

// IMPORT COMPONENT
import Home from '../src/pages/home/home';
import SeConnecter from './pages/login/seconnecter';
import UserList from './pages/users/userlist';

import Settings from './pages/settings/settings';
import SettingsRole from './pages/settings/role';
import SettingsPreference from './pages/settings/preference';
import SettingsRoleDetail from './pages/settings/roledetail';

import Organisation from './pages/organisation/organisation';
import OrganisationInvest from './pages/organisation/investisseurs';
import OrganisationPorteurDeProjet from './pages/organisation/porteurdeprojet';
import OrganisationFinancier from './pages/organisation/financier';

import Payments from './pages/payments/payments';
import EndPayments from './pages/payments/termine';
import PendingPayments from './pages/payments/encours';
import PaymentDetail from './pages/payments/paymentdetail';

import Investissement from './pages/investissement/investissement';
import RapportInvest from './pages/investissement/rapportinvest';

import Financier from './pages/users/financier';
import Investisseurs from './pages/users/investisseurs';
import PorteurDeProjet from './pages/users/porteurdeprojet';
import Requete from './pages/users/requete';

import Project from './pages/project/project';
import Rapport from './pages/project/rapport';
import ProjectDetail from './pages/project/details';

import './App.css';



const App = () => {

  let route = (
    <Switch>
      <Route path="/home" component={Home} />
      <Route path="/se-connecter" component={SeConnecter} />

      <Route path="/setting" component={Settings} />
      <Route path="/setting-role" component={SettingsRole} />
      <Route path="/setting-preference" component={SettingsPreference} />
      <Route path="/setting-role-detail" component={SettingsRoleDetail} />

      {/* <Route path="/organisation" component={Organisation} /> */}
      <Route path="/organisation" component={OrganisationFinancier} />
      {/* <Route path="/organisation-financier" component={OrganisationFinancier} /> */}
      <Route path="/organisation-porteur-de-projet" component={OrganisationPorteurDeProjet} />
      <Route path="/organisation-investisseur" component={OrganisationInvest} />

      <Route path="/payment" component={Payments} />
      <Route path="/pending-payment" component={PendingPayments} />
      <Route path="/end-payment" component={EndPayments} />
      <Route path="/payment-detail" component={PaymentDetail} />

      <Route path="/invest" component={Investissement} />
      <Route path="/rapport-invest" component={RapportInvest} />

      <Route path="/project" component={Project} />
      <Route path="/rapport" component={Rapport} />
      <Route path="/project-detail" component={ProjectDetail} />

      <Route path="/userlist" component={UserList} />
      <Route path="/financier" component={Financier} />
      <Route path="/investisseurs" component={Investisseurs} />
      <Route path="/porteurdeprojet" component={PorteurDeProjet} />
      <Route path="/requete" component={Requete} />

    </Switch>
  )

  return (
    <>
      {route}
    </>
  )


}

export default withRouter(App);

