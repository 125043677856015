import React,{} from 'react';

const Footer = () => {

    return(
        <div id="kt_app_footer" class="app-footer">

        <div class="app-container container-xxl d-flex flex-column flex-md-row flex-center flex-md-stack py-3">

            <div class="text-dark order-2 order-md-1">
                <span class="text-muted fw-semibold me-1">2024&copy;</span>
                <a href="https://keenthemes.com" target="_blank" class="text-gray-800 text-hover-primary">Infinity-africa Technologie</a>
            </div>


            <ul class="menu menu-gray-600 menu-hover-primary fw-semibold order-1">
                <li class="menu-item">
                    <a href="https://keenthemes.com" target="_blank" class="menu-link px-2">A propos</a>
                </li>
                <li class="menu-item">
                    <a href="https://devs.keenthemes.com" target="_blank" class="menu-link px-2">Aide</a>
                </li>
            </ul>

        </div>

    </div>
    )
}

export default Footer;