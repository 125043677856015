import * as actionTypes from '../../types/layouts';


const initialState = {
    openUserDrawer: false,
    openProjectDrawer: false,
    openInvestDrawer: false,
    openPaymentDrawer: false,
    openOrganisationDrawer: false,
    openSettingDrawer: false,
}


export const layoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.OPEN_USER_DRAWER:
            return {
                ...state,
                openUserDrawer: action.value
            }
        case actionTypes.OPEN_PROJECT_DRAWER:
            return {
                ...state,
                openProjectDrawer: action.value
            }
        case actionTypes.OPEN_INVEST_DRAWER:
            return {
                ...state,
                openInvestDrawer: action.value
            }
        case actionTypes.OPEN_PAYMENT_DRAWER:
            return {
                ...state,
                openPaymentDrawer: action.value
            }
        case actionTypes.OPEN_ORGANISATION_DRAWER:
            return {
                ...state,
                openOrganisationDrawer: action.value
            }
        case actionTypes.OPEN_SETTING_DRAWER:
            return {
                ...state,
                openSettingDrawer: action.value
            }

        default:
            return state
    }

}