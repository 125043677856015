import React, { useState } from 'react';

// IMPORT IMAGES
import UserImage from '../../assets/images/logo.jpeg';

const Header = () => {
    const [showThemBoard, setShowThemeBoard] = useState(false);


    const handleThemeBoard = () => {
        setShowThemeBoard(!showThemBoard)
    }


    return (
        //<div id="kt_app_toolbar" class="app-toolbar py-4 py-lg-6 mb-8 mb-lg-10" data-kt-sticky="true" data-kt-sticky-name="app-toolbar-sticky" data-kt-sticky-offset="{default: 'true', lg: '300px'}">

        <div id="kt_app_toolbar" class="app-toolbar py-4 py-lg-6 mb-8 mb-lg-10" data-kt-sticky="true" data-kt-sticky-name="app-toolbar-sticky" data-kt-sticky-offset="{default: 'false', lg: '300px'}" style={{ animationDuration: "0.3s", top: "0px" }} data-kt-sticky-enabled="true">

            <div id="kt_app_toolbar_container" class="app-container container-xxl d-flex flex-stack flex-wrap flex-lg-nowrap gap-4">

                <div class="d-flex align-items-center">

                    <img src="assets/media/svg/brand-logos/layer.svg" class="w-40px me-5" alt="" />

                    <div class="page-title py-2 py-sm-0 d-flex flex-column justify-content-center me-3">

                        <h1 class="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Sinsi - Admin Team

                            <span class="page-desc text-muted fs-7 fw-semibold pt-2">technologie@infinity-africa.com</span>
                        </h1>

                    </div>
                </div>

                <div class="d-flex align-items-center flex-wrap flex-lg-nowrap gap-4 gap-lg-10">

                    <div class="app-navbar-item ms-1 ms-md-4">
                        <a onClick={handleThemeBoard} class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px" data-kt-menu-trigger="{default:'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                            <i class="ki-duotone ki-night-day theme-light-show fs-1"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></i>
                            <i class="ki-duotone ki-moon theme-dark-show fs-1"><span class="path1"></span><span class="path2"></span></i></a>

                        <div class={showThemBoard ? "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px show" : "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px"} data-kt-menu="true" data-kt-element="theme-mode-menu" style={{ zIndex: 107, position: 'fixed', inset: '0px 0px auto auto', margin: '0px', transform: 'translate(-270px,74px)' }} data-proper-placement="bottom-end">
                            <div class="menu-item px-3 my-0">
                                <a href="#" class="menu-link px-3 py-2 active" data-kt-element="mode" data-kt-value="light">
                                    <span class="menu-icon" data-kt-element="icon">
                                        <i class="ki-duotone ki-night-day fs-2"><span class="path1"></span><span class="path2"></span><span class="path3"></span><span class="path4"></span><span class="path5"></span><span class="path6"></span><span class="path7"></span><span class="path8"></span><span class="path9"></span><span class="path10"></span></i>            </span>
                                    <span class="menu-title">
                                        Light
                                    </span>
                                </a>
                            </div>



                            <div class="menu-item px-3 my-0">
                                <a href="#" class="menu-link px-3 py-2" data-kt-element="mode" data-kt-value="dark">
                                    <span class="menu-icon" data-kt-element="icon">
                                        <i class="ki-duotone ki-moon fs-2"><span class="path1"></span><span class="path2"></span></i>            </span>
                                    <span class="menu-title">
                                        Dark
                                    </span>
                                </a>
                            </div>
                        </div>

                    </div>

                    <div class="cursor-pointer symbol symbol-35px" data-kt-menu-trigger="{default: 'click', lg: 'hover'}" data-kt-menu-attach="parent" data-kt-menu-placement="bottom-end">
                        <img src={UserImage} class="rounded-3" alt="user" />
                    </div>

                    {/* <div class="d-flex align-items-center gap-4 gap-lg-8">

                        <div class="d-flex flex-column">

                            <span class="text-gray-900 fw-bold fs-6 mb-1">$23K</span>


                            <div class="text-gray-500 fw-semibold fs-7">Avg. Sales</div>

                        </div>

                        <div class="d-flex flex-column">

                            <span class="text-gray-900 fw-bold fs-6 mb-1">$1,748.03</span>


                            <div class="text-gray-500 fw-semibold fs-7">Today Spending</div>

                        </div>


                        <div class="d-flex flex-column">

                            <span class="text-gray-900 fw-bold fs-6 mb-1">3.8%</span>


                            <div class="text-gray-500 fw-semibold fs-7">Overall Share</div>

                        </div>


                        <div class="d-flex flex-column">

                            <span class="text-gray-900 fw-bold fs-6 mb-1">-7.4%</span>


                            <div class="text-gray-500 fw-semibold fs-7">7 Days</div>

                        </div>

                    </div> */}

                    <div class="d-flex align-items-center gap-2 gapl-lg-4">




                        {/* <div class="m-0">

                            <a href="#" class="btn btn-flex btn-sm h-40px btn-light fw-bold" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">Decline</a>

                            <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c89eaae8">

                                <div class="px-7 py-5">
                                    <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                </div>

                                <div class="separator border-gray-200"></div>

                                <div class="px-7 py-5">

                                    <div class="mb-10">

                                        <label class="form-label fw-semibold">Status:</label>

                                        <div>
                                            <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c89eaae8" data-allow-clear="true">
                                                <option></option>
                                                <option value="1">Approved</option>
                                                <option value="2">Pending</option>
                                                <option value="2">In Process</option>
                                                <option value="2">Rejected</option>
                                            </select>
                                        </div>

                                    </div>

                                    <div class="mb-10">

                                        <label class="form-label fw-semibold">Member Type:</label>

                                        <div class="d-flex">

                                            <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                <input class="form-check-input" type="checkbox" value="1" />
                                                <span class="form-check-label">Author</span>
                                            </label>


                                            <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                <span class="form-check-label">Customer</span>
                                            </label>

                                        </div>

                                    </div>


                                    <div class="mb-10">

                                        <label class="form-label fw-semibold">Notifications:</label>

                                        <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                            <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                            <label class="form-check-label">Enabled</label>
                                        </div>

                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                        <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                    </div>

                                </div>

                            </div>

                        </div>
                        <a href="#" class="btn btn-flex btn-sm h-40px fw-bold btn-dark" data-bs-toggle="modal" data-bs-target="#kt_modal_create_campaign">Complete</a> */}
                    </div>

                </div>

            </div>

        </div>
    )
};

export default Header;