import React from 'react';
import Logo from '../../assets/images/logo.png';
import Logo2 from '../../assets/images/logo.jpeg';

export default function ProjectSetting() {
    return (
        <div>
            <div class="card">

                <div class="card-header">

                    <div class="card-title fs-3 fw-bold">Project Settings</div>

                </div>


                <form id="kt_project_settings_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">

                    <div class="card-body p-9">

                        <div class="row mb-5">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Project Logo</div>
                            </div>


                            <div class="col-lg-8">

                                <div class="image-input image-input-outline" data-kt-image-input="true" style={{backgroundImage: Logo2}}>

                                    <img class="image-input-wrapper w-125px h-125px bgi-position-center" src={Logo2}/>


                                    <label class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="change" data-bs-toggle="tooltip" aria-label="Change avatar" data-bs-original-title="Change avatar" data-kt-initialized="1">
                                        <i class="ki-outline ki-pencil fs-7"></i>

                                        <input type="file" name="avatar" accept=".png, .jpg, .jpeg" />
                                        <input type="hidden" name="avatar_remove" />
                                        0
                                    </label>


                                    <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="cancel" data-bs-toggle="tooltip" aria-label="Cancel avatar" data-bs-original-title="Cancel avatar" data-kt-initialized="1">
                                        <i class="ki-outline ki-cross fs-2"></i>
                                    </span>


                                    <span class="btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-white shadow" data-kt-image-input-action="remove" data-bs-toggle="tooltip" aria-label="Remove avatar" data-bs-original-title="Remove avatar" data-kt-initialized="1">
                                        <i class="ki-outline ki-cross fs-2"></i>
                                    </span>

                                </div>


                                <div class="form-text">Allowed file types: png, jpg, jpeg.</div>

                            </div>

                        </div>


                        <div class="row mb-8">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Project Name</div>
                            </div>


                            <div class="col-xl-9 fv-row fv-plugins-icon-container">
                                <input type="text" class="form-control form-control-solid" name="name" value="9 Degree Award" />
                                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
                        </div>


                        <div class="row mb-8">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Project Type</div>
                            </div>


                            <div class="col-xl-9 fv-row fv-plugins-icon-container">
                                <input type="text" class="form-control form-control-solid" name="type" value="Client Relationship" />
                                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>
                        </div>


                        <div class="row mb-8">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Project Description</div>
                            </div>


                            <div class="col-xl-9 fv-row fv-plugins-icon-container">
                                <textarea name="description" class="form-control form-control-solid h-100px">Organize your thoughts with an outline. Here’s the outlining strategy I use. I promise it works like a charm. Not only will it make writing your blog post easier, it’ll help you make your message</textarea>
                                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>

                        </div>


                        <div class="row mb-8">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Due Date</div>
                            </div>


                            <div class="col-xl-9 fv-row fv-plugins-icon-container">
                                <div class="position-relative d-flex align-items-center">
                                    <i class="ki-outline ki-calendar-8 position-absolute ms-4 mb-1 fs-2"></i>
                                    <input class="form-control form-control-solid ps-12 flatpickr-input" name="date" placeholder="Pick a date" id="kt_datepicker_1" type="text" readonly="readonly" />
                                </div>
                                <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>

                        </div>


                        <div class="row mb-8">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Notifications</div>
                            </div>


                            <div class="col-xl-9">
                                <div class="d-flex fw-semibold h-100">
                                    <div class="form-check form-check-custom form-check-solid me-9">
                                        <input class="form-check-input" type="checkbox" value="" id="email" />
                                        <label class="form-check-label ms-3" for="email">Email</label>
                                    </div>
                                    <div class="form-check form-check-custom form-check-solid">
                                        <input class="form-check-input" style={{backgroundColor: "#8e736a"}} type="checkbox" value="" id="phone" checked="checked" />
                                        <label class="form-check-label ms-3" for="phone">Phone</label>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div class="row">

                            <div class="col-xl-3">
                                <div class="fs-6 fw-semibold mt-2 mb-3">Status</div>
                            </div>


                            <div class="col-xl-9">
                                <div class="form-check form-switch form-check-custom form-check-solid">
                                    <input class="form-check-input" style={{backgroundColor: "#8e736a"}} type="checkbox" value="" id="status" name="status" checked="checked" />
                                    <label class="form-check-label fw-semibold text-gray-400 ms-3" for="status">Active</label>
                                </div>
                            </div>

                        </div>

                    </div>


                    <div class="card-footer d-flex justify-content-end py-6 px-9">
                        <button type="reset" class="btn btn-light btn-active-light-primary me-2">Discard</button>
                        <button type="submit" class="btn" id="kt_project_settings_submit" style={{backgroundColor: "#8e736a", color: "#fff"}}>Sauvegarder</button>
                    </div>

                    <input type="hidden" /></form>

            </div>
        </div>
    )
}
