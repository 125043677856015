import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Layout from '../../components/layout/layout';

export default function Payments() {
  const history = useHistory();
  return (
    <Layout openDrawer={true}>
      <div id="kt_app_content" class="app-content flex-column-fluid">

        <div id="kt_app_content_container" class="app-container container-xxl">

          <div class="card">

            <div class="card-header border-0 pt-6">

              <div class="card-title">

                <div class="d-flex align-items-center position-relative my-1">
                  <i class="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                  <input type="text" data-kt-subscription-table-filter="search" class="form-control form-control-solid w-250px ps-12" placeholder="Recherher un paiement" />
                </div>

              </div>


              <div class="card-toolbar">

                <div class="d-flex justify-content-end" data-kt-subscription-table-toolbar="base">

                  <button type="button" class="btn me-3" style={{color: "#8e736a"}} data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                    <i class="ki-outline ki-filter fs-2" style={{color: "#8e736a"}} ></i>Filtrer</button>

                  <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true">

                    <div class="px-7 py-5">
                      <div class="fs-5 text-dark fw-bold">Filter Options</div>
                    </div>


                    <div class="separator border-gray-200"></div>


                    <div class="px-7 py-5" data-kt-subscription-table-filter="form">

                      <div class="mb-10">
                        <label class="form-label fs-6 fw-semibold">Month:</label>
                        <select class="form-select form-select-solid fw-bold select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-subscription-table-filter="month" data-hide-search="true" data-select2-id="select2-data-3-1925" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                          <option data-select2-id="select2-data-5-guwc"></option>
                          <option value="jan">January</option>
                          <option value="feb">February</option>
                          <option value="mar">March</option>
                          <option value="apr">April</option>
                          <option value="may">May</option>
                          <option value="jun">June</option>
                          <option value="jul">July</option>
                          <option value="aug">August</option>
                          <option value="sep">September</option>
                          <option value="oct">October</option>
                          <option value="nov">November</option>
                          <option value="dec">December</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-4-o9ap" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid fw-bold" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-m7wl-container" aria-controls="select2-m7wl-container"><span class="select2-selection__rendered" id="select2-m7wl-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                      </div>


                      <div class="mb-10">
                        <label class="form-label fs-6 fw-semibold">Status:</label>
                        <select class="form-select form-select-solid fw-bold select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-subscription-table-filter="status" data-hide-search="true" data-select2-id="select2-data-6-xxv2" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                          <option data-select2-id="select2-data-8-8w2p"></option>
                          <option value="Active">Active</option>
                          <option value="Expiring">Expiring</option>
                          <option value="Suspended">Suspended</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-7-epfe" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid fw-bold" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-8mek-container" aria-controls="select2-8mek-container"><span class="select2-selection__rendered" id="select2-8mek-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                      </div>


                      <div class="mb-10">
                        <label class="form-label fs-6 fw-semibold">Billing Method:</label>
                        <select class="form-select form-select-solid fw-bold select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-subscription-table-filter="billing" data-hide-search="true" data-select2-id="select2-data-9-l2fl" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                          <option data-select2-id="select2-data-11-wki0"></option>
                          <option value="Auto-debit">Auto-debit</option>
                          <option value="Manual - Credit Card">Manual - Credit Card</option>
                          <option value="Manual - Cash">Manual - Cash</option>
                          <option value="Manual - Paypal">Manual - Paypal</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-10-jin6" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid fw-bold" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-nq79-container" aria-controls="select2-nq79-container"><span class="select2-selection__rendered" id="select2-nq79-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                      </div>


                      <div class="mb-10">
                        <label class="form-label fs-6 fw-semibold">Product:</label>
                        <select class="form-select form-select-solid fw-bold select2-hidden-accessible" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-subscription-table-filter="product" data-hide-search="true" data-select2-id="select2-data-12-hlub" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                          <option data-select2-id="select2-data-14-sye2"></option>
                          <option value="Basic">Basic</option>
                          <option value="Basic Bundle">Basic Bundle</option>
                          <option value="Teams">Teams</option>
                          <option value="Teams Bundle">Teams Bundle</option>
                          <option value="Enterprise">Enterprise</option>
                          <option value=" Enterprise Bundle">Enterprise Bundle</option>
                        </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-13-5q6q" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid fw-bold" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-k3je-container" aria-controls="select2-k3je-container"><span class="select2-selection__rendered" id="select2-k3je-container" role="textbox" aria-readonly="true" title="Select option"><span class="select2-selection__placeholder">Select option</span></span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>
                      </div>


                      <div class="d-flex justify-content-end">
                        <button type="reset" class="btn btn-light btn-active-light-primary fw-semibold me-2 px-6" data-kt-menu-dismiss="true" data-kt-subscription-table-filter="reset">Reset</button>
                        <button type="submit" class="btn btn-primary fw-semibold px-6" data-kt-menu-dismiss="true" data-kt-subscription-table-filter="filter">Apply</button>
                      </div>

                    </div>

                  </div>



                  <button type="button" class="btn me-3" style={{color: "#8e736a"}} data-bs-toggle="modal" data-bs-target="#kt_subscriptions_export_modal">
                    <i class="ki-outline ki-exit-up fs-2" style={{color: "#8e736a"}}></i>Exporter</button>


                  <a href="#" class="btn" style={{backgroundColor: "#8e736a", color: "#fff"}}>
                    <i class="ki-outline ki-plus fs-2" style={{color: "#fff"}}></i>Ajouter un paiement</a>

                </div>


                <div class="d-flex justify-content-end align-items-center d-none" data-kt-subscription-table-toolbar="selected">
                  <div class="fw-bold me-5">
                    <span class="me-2" data-kt-subscription-table-select="selected_count"></span>Selected</div>
                  <button type="button" class="btn btn-danger" data-kt-subscription-table-select="delete_selected">Delete Selected</button>
                </div>

              </div>

            </div>


            <div class="card-body pt-0">

              <div id="kt_subscriptions_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer"><div class="table-responsive">
                <table class="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer" id="kt_subscriptions_table">
                  <thead>
                    <tr class="text-start text-muted fw-bold fs-7 text-uppercase gs-0"><th class="w-10px pe-2 sorting_disabled" rowspan="1" colspan="1" style={{ width: "29.9px" }} aria-label="">
                      <div class="form-check form-check-sm form-check-custom form-check-solid me-3">
                        <input class="form-check-input" type="checkbox" data-kt-check="true" data-kt-check-target="#kt_subscriptions_table .form-check-input" value="1" />
                      </div>
                    </th>
                      <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_subscriptions_table" rowspan="1" colspan="1" style={{ width: "162.75px" }} aria-label="Customer: activate to sort column ascending">Customer</th>
                      <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_subscriptions_table" rowspan="1" colspan="1" style={{ width: "162.75px" }} aria-label="Status: activate to sort column ascending">Status</th>
                      <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_subscriptions_table" rowspan="1" colspan="1" style={{ width: "174.033px" }} aria-label="Billing: activate to sort column ascending">Billing</th>
                      <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_subscriptions_table" rowspan="1" colspan="1" style={{ width: "167.1px" }} aria-label="Product: activate to sort column ascending">Product</th>
                      <th class="min-w-125px sorting" tabindex="0" aria-controls="kt_subscriptions_table" rowspan="1" colspan="1" style={{ width: "162.767px" }} aria-label="Created Date: activate to sort column ascending">Created Date</th>
                      <th class="text-end min-w-70px sorting_disabled" rowspan="1" colspan="1" style={{ width: "133.2px" }} aria-label="Actions">Actions</th>
                    </tr>
                  </thead>
                  <tbody class="text-gray-600 fw-semibold">
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>
                    <tr class="odd">
                      <td>
                        <div class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="checkbox" value="1" />
                        </div>
                      </td>
                      <td>
                        <Link to="/payment-detail" class="text-gray-800 text-hover-primary mb-1">Emma Smith</Link>
                      </td>
                      <td>
                        <div class="badge badge-light-success">Active</div>
                      </td>
                      <td>
                        <div class="badge badge-light">Auto-debit</div>
                      </td>
                      <td>Basic</td>
                      <td data-order="2023-01-25T00:00:00+00:00">Jul 25, 2023</td>
                      <td>
                        <td class="text-end">
                          <i class="ki-duotone ki-pencil fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                          </i>
                          <i class="ki-duotone ki-eye fs-2" style={{ marginRight: '1rem', cursor: 'pointer' }} onClick={() => history.push('/payment-detail') }>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                          </i>
                          <i class="ki-duotone ki-trash-square fs-2" style={{ cursor: 'pointer' }}>
                            <span class="path1"></span>
                            <span class="path2"></span>
                            <span class="path3"></span>
                            <span class="path4"></span>
                          </i>
                        </td>
                      </td>
                    </tr>


                  </tbody>
                </table>
              </div><div class="row">
                  <div class="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start"></div>
                  <div class="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
                    <div class="dataTables_paginate paging_simple_numbers" id="kt_subscriptions_table_paginate">
                      <ul class="pagination">
                        <li class="paginate_button page-item previous disabled" id="kt_subscriptions_table_previous">
                          <a href="#" aria-controls="kt_subscriptions_table" data-dt-idx="0" tabindex="0" class="page-link">
                            <i class="previous"></i>
                          </a>
                        </li>
                        <li class="paginate_button page-item"style={{backgroundColor: "#8e736a"}}>
                          <a href="#" aria-controls="kt_subscriptions_table" data-dt-idx="1" tabindex="0" class="page-link" style={{color: "#fff"}}>1</a>
                        </li>
                        <li class="paginate_button page-item "><a href="#" aria-controls="kt_subscriptions_table" data-dt-idx="2" tabindex="0" class="page-link">2</a>
                        </li>
                        <li class="paginate_button page-item next" id="kt_subscriptions_table_next"><a href="#" aria-controls="kt_subscriptions_table" data-dt-idx="3" tabindex="0" class="page-link">
                          <i class="next"></i>
                        </a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>



          <div class="modal fade" id="kt_subscriptions_export_modal" tabindex="-1" aria-hidden="true">

            <div class="modal-dialog modal-dialog-centered mw-650px">

              <div class="modal-content">

                <div class="modal-header">

                  <h2 class="fw-bold">Export Subscriptions</h2>


                  <div id="kt_subscriptions_export_close" class="btn btn-icon btn-sm btn-active-icon-primary">
                    <i class="ki-outline ki-cross fs-1"></i>
                  </div>

                </div>


                <div class="modal-body scroll-y mx-5 mx-xl-15 my-7">

                  <form id="kt_subscriptions_export_form" class="form fv-plugins-bootstrap5 fv-plugins-framework" action="#">

                    <div class="fv-row mb-10">

                      <label class="fs-5 fw-semibold form-label mb-5">Select Export Format:</label>


                      <select data-control="select2" data-placeholder="Select a format" data-hide-search="true" name="format" class="form-select form-select-solid select2-hidden-accessible" data-select2-id="select2-data-15-u7l4" tabindex="-1" aria-hidden="true" data-kt-initialized="1">
                        <option value="excell" data-select2-id="select2-data-17-a05d">Excel</option>
                        <option value="pdf">PDF</option>
                        <option value="cvs">CVS</option>
                        <option value="zip">ZIP</option>
                      </select><span class="select2 select2-container select2-container--bootstrap5" dir="ltr" data-select2-id="select2-data-16-fhof" style={{ width: "100%" }}><span class="selection"><span class="select2-selection select2-selection--single form-select form-select-solid" role="combobox" aria-haspopup="true" aria-expanded="false" tabindex="0" aria-disabled="false" aria-labelledby="select2-format-4r-container" aria-controls="select2-format-4r-container"><span class="select2-selection__rendered" id="select2-format-4r-container" role="textbox" aria-readonly="true" title="Excel">Excel</span><span class="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span class="dropdown-wrapper" aria-hidden="true"></span></span>

                    </div>


                    <div class="fv-row mb-10 fv-plugins-icon-container">

                      <label class="fs-5 fw-semibold form-label mb-5">Select Date Range:</label>


                      <input class="form-control form-control-solid flatpickr-input" placeholder="Pick a date" name="date" type="hidden" /><input class="form-control form-control-solid form-control input" placeholder="Pick a date" tabindex="0" type="text" readonly="readonly" />

                      <div class="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div></div>


                    <div class="row fv-row mb-15">

                      <label class="fs-5 fw-semibold form-label mb-5">Payment Type:</label>


                      <div class="d-flex flex-column">

                        <label class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                          <input class="form-check-input" type="checkbox" value="1" checked="checked" name="payment_type" />
                          <span class="form-check-label text-gray-600 fw-semibold">All</span>
                        </label>


                        <label class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                          <input class="form-check-input" type="checkbox" value="2" checked="checked" name="payment_type" />
                          <span class="form-check-label text-gray-600 fw-semibold">Visa</span>
                        </label>


                        <label class="form-check form-check-custom form-check-sm form-check-solid mb-3">
                          <input class="form-check-input" type="checkbox" value="3" name="payment_type" />
                          <span class="form-check-label text-gray-600 fw-semibold">Mastercard</span>
                        </label>


                        <label class="form-check form-check-custom form-check-sm form-check-solid">
                          <input class="form-check-input" type="checkbox" value="4" name="payment_type" />
                          <span class="form-check-label text-gray-600 fw-semibold">American Express</span>
                        </label>

                      </div>

                    </div>


                    <div class="text-center">
                      <button type="reset" id="kt_subscriptions_export_cancel" class="btn btn-light me-3">Discard</button>
                      <button type="submit" id="kt_subscriptions_export_submit" class="btn btn-primary">
                        <span class="indicator-label">Submit</span>
                        <span class="indicator-progress">Please wait...
                          <span class="spinner-border spinner-border-sm align-middle ms-2"></span></span>
                      </button>
                    </div>

                  </form>

                </div>

              </div>

            </div>

          </div>


        </div>

      </div>
    </Layout>
  )
}
