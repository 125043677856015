import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/layout/layout';
import Logo from '../../assets/images/logo.png';
import Logo2 from '../../assets/images/logo.jpeg';
import User from '../../assets/images/user.jpg'

export default function Project() {

  const history = useHistory();

  const [rightDrawer, setRightDrawer] = useState(false)

  const [type, setType] = useState("null")

  const handleAddCustomer = () => {
    setType("addcustomer")
    setRightDrawer(true)
  }

  const handleCloseRightDrawer = () => {
    setType("null")
    setRightDrawer(false)
  }

  const handleFilter = () => {
    setType("filter")
    setRightDrawer(true)
  }

  return (
    <Layout openDrawer={true}>
      <div style={{ padding: '1rem' }}>
        <div className='card' style={{ backgroundColor: 'transparent' }}>
          <div style={{ padding: '1rem' }}>
            <h2 class="fs-2 fw-semibold my-2">Projets</h2>
          </div>
          <div class="card-header border-0 pt-6">

            <div class="card-title">

              <div class="d-flex align-items-center position-relative my-1">
                <i class="ki-outline ki-magnifier fs-3 position-absolute ms-5"></i>
                <input type="text" data-kt-customer-table-filter="search" class="form-control form-control-solid w-350px ps-12" placeholder="Rechercher un projet" />
              </div>

            </div>


            <div class="card-toolbar">

              <div class="d-flex justify-content-end" data-kt-customer-table-toolbar="base">

                <button onClick={handleFilter} style={{color: '#8e736a', backgroundColor:'#fff'}} type="button" class="btn btn-light-primary me-3" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                  <i class="ki-outline ki-filter fs-2" style={{color: '#8e736a'}}></i>Filtrer</button>

                <div class="menu menu-sub menu-sub-dropdown w-300px w-md-325px" data-kt-menu="true" id="kt-toolbar-filter">

                  <div class="px-7 py-5">
                    <div class="fs-4 text-dark fw-bold">Filter Options</div>
                  </div>


                  <div class="separator border-gray-200"></div>

                  <div class="px-7 py-5">

                    <div class="mb-10">

                      <label class="form-label fs-5 fw-semibold mb-3">Month:</label>


                      <select class="form-select form-select-solid fw-bold" data-kt-select2="true" data-placeholder="Select option" data-allow-clear="true" data-kt-customer-table-filter="month" data-dropdown-parent="#kt-toolbar-filter">
                        <option></option>
                        <option value="aug">August</option>
                        <option value="sep">September</option>
                        <option value="oct">October</option>
                        <option value="nov">November</option>
                        <option value="dec">December</option>
                      </select>

                    </div>


                    <div class="mb-10">

                      <label class="form-label fs-5 fw-semibold mb-3">Payment Type:</label>


                      <div class="d-flex flex-column flex-wrap fw-semibold" data-kt-customer-table-filter="payment_type">

                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                          <input class="form-check-input" type="radio" name="payment_type" value="all" checked="checked" />
                          <span class="form-check-label text-gray-600">All</span>
                        </label>


                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3 me-5">
                          <input class="form-check-input" type="radio" name="payment_type" value="visa" />
                          <span class="form-check-label text-gray-600">Visa</span>
                        </label>


                        <label class="form-check form-check-sm form-check-custom form-check-solid mb-3">
                          <input class="form-check-input" type="radio" name="payment_type" value="mastercard" />
                          <span class="form-check-label text-gray-600">Mastercard</span>
                        </label>


                        <label class="form-check form-check-sm form-check-custom form-check-solid">
                          <input class="form-check-input" type="radio" name="payment_type" value="american_express" />
                          <span class="form-check-label text-gray-600">American Express</span>
                        </label>

                      </div>

                    </div>


                    <div class="d-flex justify-content-end">
                      <button type="reset" class="btn btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true" data-kt-customer-table-filter="reset">Reset</button>
                      <button type="submit" class="btn btn-primary" data-kt-menu-dismiss="true" data-kt-customer-table-filter="filter">Apply</button>
                    </div>

                  </div>

                </div>



                <button type="button" class="btn btn-light-primary me-3" style={{color: '#8e736a', backgroundColor:'#fff'}} data-bs-toggle="modal" data-bs-target="#kt_customers_export_modal">
                  <i class="ki-outline ki-exit-up fs-2" style={{color: '#8e736a'}}></i>Exporter</button>

                <button type="button" onClick={handleAddCustomer} class="btn" style={{backgroundColor: '#8e736a', color:'#fff'}} data-bs-toggle="modal" data-bs-target="#kt_modal_add_customer">Ajouter un projet</button>

              </div>


              <div class="d-flex justify-content-end align-items-center d-none" data-kt-customer-table-toolbar="selected">
                <div class="fw-bold me-5">
                  <span class="me-2" data-kt-customer-table-select="selected_count"></span>Selected</div>
                <button type="button" class="btn btn-danger" data-kt-customer-table-select="delete_selected">Delete Selected</button>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="my-5" style={{ padding: '1rem' }}>
        <div>

          {/* <div class="m-0">

            <select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-sm bg-body border-body fw-bold w-125px">
              <option value="Active" selected="selected">Active</option>
              <option value="Approved">En cours</option>
              <option value="Declined">To Do</option>
              <option value="En cours">Completed</option>
            </select>

          </div> */}

        </div>
        <div class="row" style={{ padding: '3rem' }}>
          <div class="col-md-4 mb-3" >
            <span class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </span>
          </div>
          <div class="col-md-4">
            <a href="#" class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4 ">
            <a href="#" class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4 mb-3 ">
            <a href="#" class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4 ">
            <a href="#" class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4 ">
            <a href="#" class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4 ">
            <a href="#" class="card border-hover-primary">
              <div class="card-header border-0 pt-9">
                <div class="card-title m-0">

                  <div class="symbol symbol-50px w-50px bg-light">
                    <img src={Logo2} alt="image" class="p-3" style={{ borderRadius: '5rem' }} />
                  </div>
                </div>

                <div class="card-toolbar">
                  <span class="badge badge-light-primary fw-bold me-auto px-4 py-3" style={{backgroundColor: '#8e736a', color: '#fff'}}>En cours</span>
                </div>
              </div>

              <div class="card-body p-9">
                <div class="fs-3 fw-bold text-dark">Fitnes App</div>

                <p class="text-gray-400 fw-semibold fs-5 mt-1 mb-7">Application CRM pour l'efficacité des RH</p>

                <div class="d-flex flex-wrap mb-5">

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-7 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">Nov 10, 2023</div>
                    <div class="fw-semibold text-gray-400">Date d'échéance</div>
                  </div>

                  <div class="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 mb-3">
                    <div class="fs-6 text-gray-800 fw-bold">$284,900.00</div>
                    <div class="fw-semibold text-gray-400">Budget</div>
                  </div>
                </div>

                <div class="h-4px w-100 bg-light mb-5" data-bs-toggle="tooltip" title="This project 50% completed">
                  <div class="bg-primary rounded h-4px" role="progressbar" style={{ width: "50%" }} aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <div class="symbol-group symbol-hover">

                    <div>
                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Emma Smith">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Rudy Stone">
                        <img alt="Pic" src={User} />
                      </div>

                      <div class="symbol symbol-35px symbol-circle" data-bs-toggle="tooltip" title="Susan Redwood">
                        <span class="symbol-label bg-primary text-inverse-primary fw-bold">S</span>
                      </div>
                    </div>
                  </div>
                  <div>
                    <i class="ki-duotone ki-eye" style={{ fontSize: '2rem', cursor: 'pointer' }} onClick={() => history.push('/project-detail')}>
                      <span class="path1"></span>
                      <span class="path2"></span>
                      <span class="path3"></span>
                    </i>
                  </div>
                </div>
              </div>
            </a>
          </div>



        </div>

      </div>
    </Layout>
  )
}
