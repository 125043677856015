import React from 'react';
import Logo from '../../assets/images/logo.png';
import Logo2 from '../../assets/images/logo.jpeg';
import User from '../../assets/images/user.jpg'

export default function ProjectOverview() {
    return (
        <div id="component">
            <div class="row gx-6 gx-xl-9">

                <div class="col-lg-6" style={{marginBottom: '2rem'}}>

                    <div class="card card-flush h-lg-100">

                        <div class="card-header mt-6">

                            <div class="card-title flex-column">
                                <h3 class="fw-bold mb-1">Résumé des taches</h3>
                                <div class="fs-6 fw-semibold text-gray-400">24 Taches en retard</div>
                            </div>


                            <div class="card-toolbar">
                                <a href="#" class="btn btn-light btn-sm">Voir Taches</a>
                            </div>

                        </div>


                        <div class="card-body p-9 pt-5">

                            <div class="d-flex flex-wrap">

                                <div class="position-relative d-flex flex-center h-175px w-175px me-15 mb-7">
                                    <div class="position-absolute translate-middle start-50 top-50 d-flex flex-column flex-center">
                                        <span class="fs-2qx fw-bold">237</span>
                                        <span class="fs-6 fw-semibold text-gray-400">Taches en total</span>
                                    </div>
                                    <canvas id="project_overview_chart"></canvas>
                                </div>


                                <div class="d-flex flex-column justify-content-center flex-row-fluid pe-11 mb-5">

                                    <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                                        <div class="bullet bg-primary me-3"></div>
                                        <div class="text-gray-400">Actif</div>
                                        <div class="ms-auto fw-bold text-gray-700">30</div>
                                    </div>


                                    <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                                        <div class="bullet bg-success me-3"></div>
                                        <div class="text-gray-400">Termniné</div>
                                        <div class="ms-auto fw-bold text-gray-700">45</div>
                                    </div>


                                    <div class="d-flex fs-6 fw-semibold align-items-center mb-3">
                                        <div class="bullet bg-danger me-3"></div>
                                        <div class="text-gray-400">En retard</div>
                                        <div class="ms-auto fw-bold text-gray-700">0</div>
                                    </div>


                                    <div class="d-flex fs-6 fw-semibold align-items-center">
                                        <div class="bullet bg-gray-300 me-3"></div>
                                        <div class="text-gray-400">Deja commencé</div>
                                        <div class="ms-auto fw-bold text-gray-700">25</div>
                                    </div>

                                </div>

                            </div>


                            {/* <div class="notice d-flex bg-light-primary rounded border-primary border border-dashed p-6">

                                <div class="d-flex flex-stack flex-grow-1">

                                    <div class="fw-semibold">
                                        <div class="fs-6 text-gray-700">
                                            <a href="#" class="fw-bold me-1">Invite New .NET Collaborators</a>to create great outstanding business to business .jsp modutr class scripts</div>
                                    </div>

                                </div>

                            </div> */}

                        </div>

                    </div>

                </div>


                {/* <div class="col-lg-6">

                    <div class="card card-flush h-lg-100">

                        <div class="card-header mt-6">

                            <div class="card-title flex-column">
                                <h3 class="fw-bold mb-1">Tasks Over Time</h3>

                                <div class="fs-6 d-flex text-gray-400 fs-6 fw-semibold">

                                    <div class="d-flex align-items-center me-6">
                                        <span class="menu-bullet d-flex align-items-center me-2">
                                            <span class="bullet bg-success"></span>
                                        </span>Complete</div>


                                    <div class="d-flex align-items-center">
                                        <span class="menu-bullet d-flex align-items-center me-2">
                                            <span class="bullet bg-primary"></span>
                                        </span>Incomplete</div>

                                </div>

                            </div>


                            <div class="card-toolbar">

                                <select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-solid form-select-sm fw-bold w-100px">
                                    <option value="1">2020 Q1</option>
                                    <option value="2">2020 Q2</option>
                                    <option value="3" selected="selected">2020 Q3</option>
                                    <option value="4">2020 Q4</option>
                                </select>

                            </div>

                        </div>


                        <div class="card-body pt-10 pb-0 px-5">

                            <div id="kt_project_overview_graph" class="card-rounded-bottom" style={{ height: "300px" }}></div>

                        </div>

                    </div>

                </div> */}


                {/* <div class="col-lg-6">

                    <div class="card card-flush h-lg-100">

                        <div class="card-header mt-6">

                            <div class="card-title flex-column">
                                <h3 class="fw-bold mb-1">What's on the road?</h3>
                                <div class="fs-6 text-gray-400">Total 482 participants</div>
                            </div>


                            <div class="card-toolbar">

                                <select name="status" data-control="select2" data-hide-search="true" class="form-select form-select-solid form-select-sm fw-bold w-100px">
                                    <option value="1" selected="selected">Options</option>
                                    <option value="2">Option 1</option>
                                    <option value="3">Option 2</option>
                                    <option value="4">Option 3</option>
                                </select>

                            </div>

                        </div>


                        <div class="card-body p-9 pt-4">

                            <ul class="nav nav-pills d-flex flex-nowrap hover-scroll-x py-2">

                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_0">
                                        <span class="opacity-50 fs-7 fw-semibold">Su</span>
                                        <span class="fs-6 fw-bold">22</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary active" data-bs-toggle="tab" href="#kt_schedule_day_1">
                                        <span class="opacity-50 fs-7 fw-semibold">Mo</span>
                                        <span class="fs-6 fw-bold">23</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_2">
                                        <span class="opacity-50 fs-7 fw-semibold">Tu</span>
                                        <span class="fs-6 fw-bold">24</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_3">
                                        <span class="opacity-50 fs-7 fw-semibold">We</span>
                                        <span class="fs-6 fw-bold">25</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_4">
                                        <span class="opacity-50 fs-7 fw-semibold">Th</span>
                                        <span class="fs-6 fw-bold">26</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_5">
                                        <span class="opacity-50 fs-7 fw-semibold">Fr</span>
                                        <span class="fs-6 fw-bold">27</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_6">
                                        <span class="opacity-50 fs-7 fw-semibold">Sa</span>
                                        <span class="fs-6 fw-bold">28</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_7">
                                        <span class="opacity-50 fs-7 fw-semibold">Su</span>
                                        <span class="fs-6 fw-bold">29</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_8">
                                        <span class="opacity-50 fs-7 fw-semibold">Mo</span>
                                        <span class="fs-6 fw-bold">30</span>
                                    </a>
                                </li>


                                <li class="nav-item me-1">
                                    <a class="nav-link btn d-flex flex-column flex-center rounded-pill min-w-45px me-2 py-4 px-3 btn-active-primary" data-bs-toggle="tab" href="#kt_schedule_day_9">
                                        <span class="opacity-50 fs-7 fw-semibold">Tu</span>
                                        <span class="fs-6 fw-bold">31</span>
                                    </a>
                                </li>

                            </ul>


                            <div class="tab-content">

                                <div id="kt_schedule_day_0" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">13:00 - 14:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Yannis Gloverson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">12:00 - 13:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Kendell Trevor</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">11:00 - 11:45
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Peter Marcus</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_1" class="tab-pane fade show active">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Caleb Donaldson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">13:00 - 14:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Yannis Gloverson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Kendell Trevor</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_2" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">16:30 - 17:30
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Creative Content Initiative</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Terry Robins</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">10:00 - 11:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Yannis Gloverson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">10:00 - 11:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Kendell Trevor</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_3" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Bob Harris</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Bob Harris</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">14:30 - 15:30
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Karina Clarke</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_4" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">11:00 - 11:45
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Mark Randall</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">14:30 - 15:30
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Caleb Donaldson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">11:00 - 11:45
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Dashboard UI/UX Design Review</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Mark Randall</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_5" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Marketing Campaign Discussion</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Peter Marcus</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">14:30 - 15:30
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Mark Randall</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Weekly Team Stand-Up</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Sean Bean</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_6" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Naomi Hayabusa</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">13:00 - 14:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Naomi Hayabusa</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">10:00 - 11:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Committee Review Approvals</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Yannis Gloverson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_7" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">12:00 - 13:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Caleb Donaldson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">12:00 - 13:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Karina Clarke</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Team Backlog Grooming Session</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Sean Bean</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_8" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">14:30 - 15:30
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Sales Pitch Proposal</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Karina Clarke</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">12:00 - 13:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Naomi Hayabusa</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">14:30 - 15:30
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">9 Degree Project Estimation Meeting</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">David Stevenson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>


                                <div id="kt_schedule_day_9" class="tab-pane fade show">

                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">9:00 - 10:00
                                                <span class="fs-7 text-gray-400 text-uppercase">am</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Lunch & Learn Catch Up</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">David Stevenson</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">13:00 - 14:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Project Review & Testing</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Karina Clarke</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>


                                    <div class="d-flex flex-stack position-relative mt-8">

                                        <div class="position-absolute h-100 w-4px bg-secondary rounded top-0 start-0"></div>


                                        <div class="fw-semibold ms-5 text-gray-600">

                                            <div class="fs-5">13:00 - 14:00
                                                <span class="fs-7 text-gray-400 text-uppercase">pm</span></div>


                                            <a href="#" class="fs-5 fw-bold text-gray-800 text-hover-primary mb-2">Development Team Capacity Review</a>


                                            <div class="text-gray-400">Lead by
                                                <a href="#">Karina Clarke</a></div>

                                        </div>


                                        <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Voir</a>

                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>

                </div> */}


                <div class="col-lg-6" style={{marginBottom: '2rem'}}>

                    <div class="card card-flush h-lg-100">

                        <div class="card-header mt-6">

                            <div class="card-title flex-column">
                                <h3 class="fw-bold mb-1">Derniers fichiers</h3>
                                <div class="fs-6 text-gray-400" >382 fichier au total, 2,6GB d'espace utilisés</div>
                            </div>


                            <div class="card-toolbar">
                                <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Tout voir</a>
                            </div>

                        </div>


                        <div class="card-body p-9 pt-3">

                            <div class="d-flex flex-column mb-9">

                                <div class="d-flex align-items-center mb-5">

                                    <div class="symbol symbol-30px me-5">
                                        <img alt="Icon" src="assets/media/svg/files/pdf.svg" />
                                    </div>


                                    <div class="fw-semibold">
                                        <a class="fs-6 fw-bold text-dark text-hover-primary" href="#">Project tech requirements</a>
                                        <div class="text-gray-400">2 days ago
                                            <a href="#" style={{color: "#8e736a"}}>Karina Clark</a></div>
                                    </div>


                                    <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                    </button>

                                    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccd24">

                                        <div class="px-7 py-5">
                                            <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                        </div>


                                        <div class="separator border-gray-200"></div>


                                        <div class="px-7 py-5">

                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Status:</label>


                                                <div>
                                                    <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccd24" data-allow-clear="true">
                                                        <option></option>
                                                        <option value="1">Approuvé</option>
                                                        <option value="2">En attente</option>
                                                        <option value="2">In Process</option>
                                                        <option value="2">Rejected</option>
                                                    </select>
                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Member Type:</label>


                                                <div class="d-flex">

                                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                        <span class="form-check-label">Author</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                        <span class="form-check-label">Customer</span>
                                                    </label>

                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Notifications:</label>


                                                <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                    <label class="form-check-label">Enabled</label>
                                                </div>

                                            </div>


                                            <div class="d-flex justify-content-end">
                                                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                            </div>

                                        </div>

                                    </div>


                                </div>


                                <div class="d-flex align-items-center mb-5">

                                    <div class="symbol symbol-30px me-5">
                                        <img alt="Icon" src="assets/media/svg/files/doc.svg" />
                                    </div>


                                    <div class="fw-semibold">
                                        <a class="fs-6 fw-bold text-dark text-hover-primary" href="#">Create FureStibe branding proposal</a>
                                        <div class="text-gray-400">Due in 1 day
                                            <a href="#" style={{color: "#8e736a"}}>Marcus Blake</a></div>
                                    </div>


                                    <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                    </button>

                                    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccd32">

                                        <div class="px-7 py-5">
                                            <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                        </div>


                                        <div class="separator border-gray-200"></div>


                                        <div class="px-7 py-5">

                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Status:</label>


                                                <div>
                                                    <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccd32" data-allow-clear="true">
                                                        <option></option>
                                                        <option value="1">Approuvé</option>
                                                        <option value="2">En attente</option>
                                                        <option value="2">In Process</option>
                                                        <option value="2">Rejected</option>
                                                    </select>
                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Member Type:</label>


                                                <div class="d-flex">

                                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                        <span class="form-check-label">Author</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                        <span class="form-check-label">Customer</span>
                                                    </label>

                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Notifications:</label>


                                                <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                    <label class="form-check-label">Enabled</label>
                                                </div>

                                            </div>


                                            <div class="d-flex justify-content-end">
                                                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                            </div>

                                        </div>

                                    </div>


                                </div>


                                <div class="d-flex align-items-center mb-5">

                                    <div class="symbol symbol-30px me-5">
                                        <img alt="Icon" src="assets/media/svg/files/css.svg" />
                                    </div>


                                    <div class="fw-semibold">
                                        <a class="fs-6 fw-bold text-dark text-hover-primary" href="#">Completed Project Stylings</a>
                                        <div class="text-gray-400">Due in 1 day
                                            <a href="#" style={{color: "#8e736a"}}>Terry Barry</a></div>
                                    </div>


                                    <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                    </button>

                                    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccd3e">

                                        <div class="px-7 py-5">
                                            <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                        </div>


                                        <div class="separator border-gray-200"></div>


                                        <div class="px-7 py-5">

                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Status:</label>


                                                <div>
                                                    <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccd3e" data-allow-clear="true">
                                                        <option></option>
                                                        <option value="1">Approuvé</option>
                                                        <option value="2">En attente</option>
                                                        <option value="2">In Process</option>
                                                        <option value="2">Rejected</option>
                                                    </select>
                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Member Type:</label>


                                                <div class="d-flex">

                                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                        <span class="form-check-label">Author</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                        <span class="form-check-label">Customer</span>
                                                    </label>

                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Notifications:</label>


                                                <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                    <label class="form-check-label">Enabled</label>
                                                </div>

                                            </div>


                                            <div class="d-flex justify-content-end">
                                                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                            </div>

                                        </div>

                                    </div>


                                </div>


                                <div class="d-flex align-items-center">

                                    <div class="symbol symbol-30px me-5">
                                        <img alt="Icon" src="assets/media/svg/files/ai.svg" />
                                    </div>


                                    <div class="fw-semibold">
                                        <a class="fs-6 fw-bold text-dark text-hover-primary" href="#">Create Project Wireframes</a>
                                        <div class="text-gray-400">Due in 3 days
                                            <a href="#" style={{color: "#8e736a"}}>Roth Bloom</a></div>
                                    </div>


                                    <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                        <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                    </button>

                                    <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccd4a">

                                        <div class="px-7 py-5">
                                            <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                        </div>


                                        <div class="separator border-gray-200"></div>


                                        <div class="px-7 py-5">

                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Status:</label>


                                                <div>
                                                    <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccd4a" data-allow-clear="true">
                                                        <option></option>
                                                        <option value="1">Approuvé</option>
                                                        <option value="2">En attente</option>
                                                        <option value="2">In Process</option>
                                                        <option value="2">Rejected</option>
                                                    </select>
                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Member Type:</label>


                                                <div class="d-flex">

                                                    <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                        <input class="form-check-input" type="checkbox" value="1" />
                                                        <span class="form-check-label">Author</span>
                                                    </label>


                                                    <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                        <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                        <span class="form-check-label">Customer</span>
                                                    </label>

                                                </div>

                                            </div>


                                            <div class="mb-10">

                                                <label class="form-label fw-semibold">Notifications:</label>


                                                <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                    <label class="form-check-label">Enabled</label>
                                                </div>

                                            </div>


                                            <div class="d-flex justify-content-end">
                                                <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                                <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                            </div>

                                        </div>

                                    </div>


                                </div>

                            </div>


                            <div class="notice d-flex rounded border-primary border border-dashed p-6" style={{backgroundColor: "#8e736a"}}>

                                <i class="ki-outline ki-svg/files/upload.svg fs-2tx text-primary me-4" style={{backgroundColor: "#8e736a"}} ></i>


                                <div class="d-flex flex-stack flex-grow-1" >

                                    <div class="fw-semibold">
                                        <h4 class="fw-bold" style={{color: "#fff"}}>Quelques fichiers à téléchager</h4>
                                        <div class="fs-6" style={{color: "#fff"}}>Glissez et deposer vos fichiers depuis l'ordinateur</div>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


                <div class="col-lg-6" >

                    <div class="card card-flush h-lg-100">

                        <div class="card-header mt-6">

                            <div class="card-title flex-column">
                                <h3 class="fw-bold mb-1">Nouveau collaborateurs</h3>
                                <div class="fs-6 text-gray-400">Sur un total de 482 participants</div>
                            </div>


                            <div class="card-toolbar">
                                <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Tout voir</a>
                            </div>

                        </div>


                        <div class="card-body d-flex flex-column p-9 pt-3 mb-9">

                            <div class="d-flex align-items-center mb-5">

                                <div class="me-5 position-relative">

                                    <div class="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={User} />
                                    </div>

                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary">Emma Smith</a>
                                    <div class="text-gray-400">8 En attente & 97 Completed Tasks</div>
                                </div>


                                <div class="badge badge-light ms-auto">5</div>

                            </div>


                            <div class="d-flex align-items-center mb-5">

                                <div class="me-5 position-relative">

                                    <div class="symbol symbol-35px symbol-circle">
                                        <span class="symbol-label bg-light-danger text-danger fw-semibold">M</span>
                                    </div>


                                    <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary">Melody Macy</a>
                                    <div class="text-gray-400">5 En attente & 84 Terminés</div>
                                </div>


                                <div class="badge badge-light ms-auto">8</div>

                            </div>


                            <div class="d-flex align-items-center mb-5">

                                <div class="me-5 position-relative">

                                    <div class="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={User} />
                                    </div>

                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary">Max Smith</a>
                                    <div class="text-gray-400">9 En attente & 103 Terminés</div>
                                </div>


                                <div class="badge badge-light ms-auto">9</div>

                            </div>


                            <div class="d-flex align-items-center mb-5">

                                <div class="me-5 position-relative">

                                    <div class="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={User} />
                                    </div>

                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary">Sean Bean</a>
                                    <div class="text-gray-400">3 En attente & 55 Terminés</div>
                                </div>


                                <div class="badge badge-light ms-auto">3</div>

                            </div>


                            <div class="d-flex align-items-center">

                                <div class="me-5 position-relative">

                                    <div class="symbol symbol-35px symbol-circle">
                                        <img alt="Pic" src={User} />
                                    </div>

                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-5 fw-bold text-gray-900 text-hover-primary">Brian Cox</a>
                                    <div class="text-gray-400">4 En attente & 115 Terminés</div>
                                </div>


                                <div class="badge badge-light ms-auto">4</div>

                            </div>

                        </div>

                    </div>

                </div>


                <div class="col-lg-6">

                    <div class="card card-flush h-lg-100">

                        <div class="card-header mt-6">

                            <div class="card-title flex-column">
                                <h3 class="fw-bold mb-1">Mes taches</h3>
                                <div class="fs-6 text-gray-400">25 taches au total dans le backlog</div>
                            </div>


                            <div class="card-toolbar">
                                <a href="#" class="btn btn-bg-light btn-active-color-primary btn-sm">Tout Voir</a>
                            </div>

                        </div>


                        <div class="card-body d-flex flex-column mb-9 p-9 pt-3">

                            <div class="d-flex align-items-center position-relative mb-7">

                                <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>


                                <div class="form-check form-check-custom form-check-solid ms-6 me-4">
                                    <input class="form-check-input" type="checkbox" value="" />
                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-6 fw-bold text-gray-900 text-hover-primary">Créer le logo de FureStibe </a>

                                    <div class="text-gray-400">Due in 1 day
                                        <a href="#" style={{color: "#8e736a"}}>Karina Clark</a></div>

                                </div>


                                <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                </button>

                                <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccea6">

                                    <div class="px-7 py-5">
                                        <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                    </div>


                                    <div class="separator border-gray-200"></div>


                                    <div class="px-7 py-5">

                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Status:</label>


                                            <div>
                                                <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccea6" data-allow-clear="true">
                                                    <option></option>
                                                    <option value="1">Approuvé</option>
                                                    <option value="2">En attente</option>
                                                    <option value="2">In Process</option>
                                                    <option value="2">Rejected</option>
                                                </select>
                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Member Type:</label>


                                            <div class="d-flex">

                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input class="form-check-input" type="checkbox" value="1" />
                                                    <span class="form-check-label">Author</span>
                                                </label>


                                                <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                    <span class="form-check-label">Customer</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Notifications:</label>


                                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                <label class="form-check-label">Enabled</label>
                                            </div>

                                        </div>


                                        <div class="d-flex justify-content-end">
                                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                        </div>

                                    </div>

                                </div>


                            </div>


                            <div class="d-flex align-items-center position-relative mb-7">

                                <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>


                                <div class="form-check form-check-custom form-check-solid ms-6 me-4">
                                    <input class="form-check-input" type="checkbox" value="" />
                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-6 fw-bold text-gray-900 text-hover-primary">Créer le logo de FureStibe </a>

                                    <div class="text-gray-400">En retard de 3 jours
                                        <a href="#"  style={{color: "#8e736a"}}>Rober Doe</a></div>

                                </div>


                                <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                </button>

                                <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78cceb2">

                                    <div class="px-7 py-5">
                                        <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                    </div>


                                    <div class="separator border-gray-200"></div>


                                    <div class="px-7 py-5">

                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Status:</label>


                                            <div>
                                                <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78cceb2" data-allow-clear="true">
                                                    <option></option>
                                                    <option value="1">Approuvé</option>
                                                    <option value="2">En attente</option>
                                                    <option value="2">In Process</option>
                                                    <option value="2">Rejected</option>
                                                </select>
                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Member Type:</label>


                                            <div class="d-flex">

                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input class="form-check-input" type="checkbox" value="1" />
                                                    <span class="form-check-label">Author</span>
                                                </label>


                                                <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                    <span class="form-check-label">Customer</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Notifications:</label>


                                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                <label class="form-check-label">Enabled</label>
                                            </div>

                                        </div>


                                        <div class="d-flex justify-content-end">
                                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                        </div>

                                    </div>

                                </div>


                            </div>


                            <div class="d-flex align-items-center position-relative mb-7">

                                <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>


                                <div class="form-check form-check-custom form-check-solid ms-6 me-4">
                                    <input class="form-check-input" type="checkbox" value="" />
                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-6 fw-bold text-gray-900 text-hover-primary">Créer le logo de FureStibe </a>

                                    <div class="text-gray-400">Delai d'une semaine
                                        <a href="#"  style={{color: "#8e736a"}}>Neil Owen</a></div>

                                </div>


                                <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                </button>

                                <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccebc">

                                    <div class="px-7 py-5">
                                        <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                    </div>


                                    <div class="separator border-gray-200"></div>


                                    <div class="px-7 py-5">

                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Status:</label>


                                            <div>
                                                <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccebc" data-allow-clear="true">
                                                    <option></option>
                                                    <option value="1">Approuvé</option>
                                                    <option value="2">En attente</option>
                                                    <option value="2">In Process</option>
                                                    <option value="2">Rejected</option>
                                                </select>
                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Member Type:</label>


                                            <div class="d-flex">

                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input class="form-check-input" type="checkbox" value="1" />
                                                    <span class="form-check-label">Author</span>
                                                </label>


                                                <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                    <span class="form-check-label">Customer</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Notifications:</label>


                                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                <label class="form-check-label">Enabled</label>
                                            </div>

                                        </div>


                                        <div class="d-flex justify-content-end">
                                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                        </div>

                                    </div>

                                </div>


                            </div>


                            <div class="d-flex align-items-center position-relative mb-7">

                                <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>


                                <div class="form-check form-check-custom form-check-solid ms-6 me-4">
                                    <input class="form-check-input" type="checkbox" value="" />
                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-6 fw-bold text-gray-900 text-hover-primary">Créer le logo de FureStibe </a>

                                    <div class="text-gray-400">Due in 1 week
                                        <a href="#">Olivia Wild</a></div>

                                </div>


                                <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                </button>

                                <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78ccec7">

                                    <div class="px-7 py-5">
                                        <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                    </div>


                                    <div class="separator border-gray-200"></div>


                                    <div class="px-7 py-5">

                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Status:</label>


                                            <div>
                                                <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78ccec7" data-allow-clear="true">
                                                    <option></option>
                                                    <option value="1">Approuvé</option>
                                                    <option value="2">En attente</option>
                                                    <option value="2">In Process</option>
                                                    <option value="2">Rejected</option>
                                                </select>
                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Member Type:</label>


                                            <div class="d-flex">

                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input class="form-check-input" type="checkbox" value="1" />
                                                    <span class="form-check-label">Author</span>
                                                </label>


                                                <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                    <span class="form-check-label">Customer</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Notifications:</label>


                                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                <label class="form-check-label">Enabled</label>
                                            </div>

                                        </div>


                                        <div class="d-flex justify-content-end">
                                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                        </div>

                                    </div>

                                </div>


                            </div>


                            <div class="d-flex align-items-center position-relative">

                                <div class="position-absolute top-0 start-0 rounded h-100 bg-secondary w-4px"></div>


                                <div class="form-check form-check-custom form-check-solid ms-6 me-4">
                                    <input class="form-check-input" type="checkbox" value="" />
                                </div>


                                <div class="fw-semibold">
                                    <a href="#" class="fs-6 fw-bold text-gray-900 text-hover-primary">Créer le logo de FureStibe </a>

                                    <div class="text-gray-400">Due in 2 weeks
                                        <a href="#">Sean Bean</a></div>

                                </div>


                                <button type="button" class="btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary ms-auto" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-end">
                                    <i class="ki-outline ki-element-plus fs-3" style={{color: '#8e736a'}}></i>
                                </button>

                                <div class="menu menu-sub menu-sub-dropdown w-250px w-md-300px" data-kt-menu="true" id="kt_menu_64b77c78cced1">

                                    <div class="px-7 py-5">
                                        <div class="fs-5 text-dark fw-bold">Filter Options</div>
                                    </div>


                                    <div class="separator border-gray-200"></div>


                                    <div class="px-7 py-5">

                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Status:</label>


                                            <div>
                                                <select class="form-select form-select-solid" multiple="multiple" data-kt-select2="true" data-close-on-select="false" data-placeholder="Select option" data-dropdown-parent="#kt_menu_64b77c78cced1" data-allow-clear="true">
                                                    <option></option>
                                                    <option value="1">Approuvé</option>
                                                    <option value="2">En attente</option>
                                                    <option value="2">In Process</option>
                                                    <option value="2">Rejected</option>
                                                </select>
                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Member Type:</label>


                                            <div class="d-flex">

                                                <label class="form-check form-check-sm form-check-custom form-check-solid me-5">
                                                    <input class="form-check-input" type="checkbox" value="1" />
                                                    <span class="form-check-label">Author</span>
                                                </label>


                                                <label class="form-check form-check-sm form-check-custom form-check-solid">
                                                    <input class="form-check-input" type="checkbox" value="2" checked="checked" />
                                                    <span class="form-check-label">Customer</span>
                                                </label>

                                            </div>

                                        </div>


                                        <div class="mb-10">

                                            <label class="form-label fw-semibold">Notifications:</label>


                                            <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                                                <input class="form-check-input" type="checkbox" value="" name="notifications" checked="checked" />
                                                <label class="form-check-label">Enabled</label>
                                            </div>

                                        </div>


                                        <div class="d-flex justify-content-end">
                                            <button type="reset" class="btn btn-sm btn-light btn-active-light-primary me-2" data-kt-menu-dismiss="true">Reset</button>
                                            <button type="submit" class="btn btn-sm btn-primary" data-kt-menu-dismiss="true">Apply</button>
                                        </div>

                                    </div>

                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </div>


            <div class="card card-flush mt-6 mt-xl-9">

                <div class="card-header mt-5">

                    <div class="card-title flex-column">
                        <h3 class="fw-bold mb-1">Dépenses projets</h3>
                        <div class="fs-6 text-gray-400">Total $260,300 dépensé depuis lors</div>
                    </div>


                    <div class="card-toolbar my-1">

                        <div class="me-6 my-1">
                            <select id="kt_filter_year" name="year" data-control="select2" data-hide-search="true" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="All" selected="selected">All time</option>
                                <option value="thisyear">This year</option>
                                <option value="thismonth">This month</option>
                                <option value="lastmonth">Last month</option>
                                <option value="last90days">Last 90 days</option>
                            </select>
                        </div>


                        <div class="me-4 my-1">
                            <select id="kt_filter_orders" name="orders" data-control="select2" data-hide-search="true" class="w-125px form-select form-select-solid form-select-sm">
                                <option value="All" selected="selected">All Orders</option>
                                <option value="Approuvé">Approuvé</option>
                                <option value="Declined">Declined</option>
                                <option value="In Progress">In Progress</option>
                                <option value="In Transit">In Transit</option>
                            </select>
                        </div>


                        <div class="d-flex align-items-center position-relative my-1">
                            <i class="ki-outline ki-magnifier fs-3 position-absolute ms-3"></i>
                            <input type="text" id="kt_filter_search" class="form-control form-control-solid form-select-sm w-150px ps-9" placeholder="Search Order" />
                        </div>

                    </div>

                </div>


                <div class="card-body pt-0">

                    <div class="table-responsive">

                        <table id="kt_profile_overview_table" class="table table-row-bordered table-row-dashed gy-4 align-middle fw-bold">
                            <thead class="fs-7 text-gray-400 text-uppercase">
                                <tr>
                                    <th class="min-w-250px">Manager</th>
                                    <th class="min-w-150px">Date</th>
                                    <th class="min-w-90px">Montant</th>
                                    <th class="min-w-90px">Status</th>
                                    <th class="min-w-50px text-end">Details</th>
                                </tr>
                            </thead>
                            <tbody class="fs-6">
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Emma Smith</a>
                                                <div class="fw-semibold text-gray-400">smith@kpmg.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Feb 21, 2023</td>
                                    <td>$759.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-danger text-danger fw-semibold">M</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Melody Macy</a>
                                                <div class="fw-semibold text-gray-400">melody@altbox.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Feb 21, 2023</td>
                                    <td>$552.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-1.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Max Smith</a>
                                                <div class="fw-semibold text-gray-400">max@kt.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 20, 2023</td>
                                    <td>$819.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Sean Bean</a>
                                                <div class="fw-semibold text-gray-400">sean@dellito.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 24, 2023</td>
                                    <td>$524.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-25.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Brian Cox</a>
                                                <div class="fw-semibold text-gray-400">brian@exchange.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 24, 2023</td>
                                    <td>$916.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-warning text-warning fw-semibold">C</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Mikaela Collins</a>
                                                <div class="fw-semibold text-gray-400">mik@pex.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Feb 21, 2023</td>
                                    <td>$444.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Francis Mitcham</a>
                                                <div class="fw-semibold text-gray-400">f.mit@kpmg.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Nov 10, 2023</td>
                                    <td>$699.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-danger text-danger fw-semibold">O</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Olivia Wild</a>
                                                <div class="fw-semibold text-gray-400">olivia@corpmail.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Mar 10, 2023</td>
                                    <td>$784.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-primary text-primary fw-semibold">N</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Neil Owen</a>
                                                <div class="fw-semibold text-gray-400">owen.neil@gmail.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>May 05, 2023</td>
                                    <td>$521.00</td>
                                    <td>
                                        <span class="badge badge-light-info fw-bold px-4 py-3">En cours</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-23.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Dan Wilson</a>
                                                <div class="fw-semibold text-gray-400">dam@consilting.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 24, 2023</td>
                                    <td>$920.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-danger text-danger fw-semibold">E</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Emma Bold</a>
                                                <div class="fw-semibold text-gray-400">emma@intenso.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 20, 2023</td>
                                    <td>$877.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Ana Crown</a>
                                                <div class="fw-semibold text-gray-400">ana.cf@limtel.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 24, 2023</td>
                                    <td>$579.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-info text-info fw-semibold">A</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6"  style={{color: "#8e736a"}}>Robert Doe</a>
                                                <div class="fw-semibold text-gray-400">robert@benko.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Oct 25, 2023</td>
                                    <td>$950.00</td>
                                    <td>
                                        <span class="badge badge-light-info fw-bold px-4 py-3">En cours</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-13.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">John Miller</a>
                                                <div class="fw-semibold text-gray-400">miller@mapple.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Nov 10, 2023</td>
                                    <td>$579.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-success text-success fw-semibold">L</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Lucy Kunic</a>
                                                <div class="fw-semibold text-gray-400">lucy.m@fentech.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Dec 20, 2023</td>
                                    <td>$852.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Ethan Wilder</a>
                                                <div class="fw-semibold text-gray-400">ethan@loop.com.au</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Oct 25, 2023</td>
                                    <td>$837.00</td>
                                    <td>
                                        <span class="badge badge-light-info fw-bold px-4 py-3">En cours</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-primary text-primary fw-semibold">N</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Neil Owen</a>
                                                <div class="fw-semibold text-gray-400">owen.neil@gmail.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Apr 15, 2023</td>
                                    <td>$729.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-danger text-danger fw-semibold">E</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Emma Bold</a>
                                                <div class="fw-semibold text-gray-400">emma@intenso.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>May 05, 2023</td>
                                    <td>$779.00</td>
                                    <td>
                                        <span class="badge badge-light-info fw-bold px-4 py-3">En cours</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-13.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">John Miller</a>
                                                <div class="fw-semibold text-gray-400">miller@mapple.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>May 05, 2023</td>
                                    <td>$628.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-5.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Sean Bean</a>
                                                <div class="fw-semibold text-gray-400">sean@dellito.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Aug 19, 2023</td>
                                    <td>$727.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Francis Mitcham</a>
                                                <div class="fw-semibold text-gray-400">f.mit@kpmg.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jul 25, 2023</td>
                                    <td>$859.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-warning text-warning fw-semibold">C</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Mikaela Collins</a>
                                                <div class="fw-semibold text-gray-400">mik@pex.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Sep 22, 2023</td>
                                    <td>$542.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-warning text-warning fw-semibold">C</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Mikaela Collins</a>
                                                <div class="fw-semibold text-gray-400">mik@pex.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Feb 21, 2023</td>
                                    <td>$487.00</td>
                                    <td>
                                        <span class="badge badge-light-success fw-bold px-4 py-3">Approuvé</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-12.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Ana Crown</a>
                                                <div class="fw-semibold text-gray-400">ana.cf@limtel.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Nov 10, 2023</td>
                                    <td>$524.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                                                </div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Emma Smith</a>
                                                <div class="fw-semibold text-gray-400">smith@kpmg.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jul 25, 2023</td>
                                    <td>$419.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-21.jpg" />
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Ethan Wilder</a>
                                                <div class="fw-semibold text-gray-400">ethan@loop.com.au</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Dec 20, 2023</td>
                                    <td>$447.00</td>
                                    <td>
                                        <span class="badge badge-light-danger fw-bold px-4 py-3">Rejected</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-success text-success fw-semibold">L</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Lucy Kunic</a>
                                                <div class="fw-semibold text-gray-400">lucy.m@fentech.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jul 25, 2023</td>
                                    <td>$747.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <span class="symbol-label bg-light-success text-success fw-semibold">L</span>
                                                </div>


                                                <div class="bg-success position-absolute h-8px w-8px rounded-circle translate-middle start-100 top-100 ms-n1 mt-n1"></div>

                                            </div>


                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Lucy Kunic</a>
                                                <div class="fw-semibold text-gray-400">lucy.m@fentech.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Feb 21, 2023</td>
                                    <td>$537.00</td>
                                    <td>
                                        <span class="badge badge-light-info fw-bold px-4 py-3">En cours</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-9.jpg" />
                                                </div>

                                            </div>

                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Francis Mitcham</a>
                                                <div class="fw-semibold text-gray-400">f.mit@kpmg.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Sep 22, 2023</td>
                                    <td>$961.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td>

                                        <div class="d-flex align-items-center">

                                            <div class="me-5 position-relative">

                                                <div class="symbol symbol-35px symbol-circle">
                                                    <img alt="Pic" src="assets/media/avatars/300-6.jpg" />
                                                </div>

                                            </div>

                                            <div class="d-flex flex-column justify-content-center">
                                                <a href="" class="fs-6 text-gray-800 text-hover-primary">Emma Smith</a>
                                                <div class="fw-semibold text-gray-400">smith@kpmg.com</div>
                                            </div>

                                        </div>

                                    </td>
                                    <td>Jun 24, 2023</td>
                                    <td>$651.00</td>
                                    <td>
                                        <span class="badge badge-light-warning fw-bold px-4 py-3">En attente</span>
                                    </td>
                                    <td class="text-end">
                                        <a href="#" class="btn btn-light btn-sm">Voir</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>

                </div>

            </div>
        </div>
    )
}
