import React, { } from 'react';
// IMPORT DEPENDENCIES
import {Link} from 'react-router-dom';

//IMPORT CSS

import '../../assets/css/login/plugins.bundle.css';
import '../../assets/css/login/style.bundle.css';

// IMPORT IMAGE
import Background from '../../assets/images/newbg.jpg'

const SeConnecter = () => {

    return (
        <div id="kt_body" class="app-blank">
        <div class="d-flex flex-column flex-root" id="kt_app_root" >

            <div class="d-flex flex-column flex-lg-row flex-column-fluid" style={{height:'100vh'}}>

                <a href="../../demo29/dist/index.html" class="d-block d-lg-none mx-auto py-20">
                    <img alt="Logo" src="assets/media/logos/default.svg" class="theme-light-show h-25px" />
                    <img alt="Logo" src="assets/media/logos/default-dark.svg" class="theme-dark-show h-25px" />
                </a>

                <div class="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10" >

                    <div class="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">

                        <div class="d-flex flex-stack py-2">

                            <div class="me-2"></div>

                        </div>

                        <div class="py-20" style={{marginBottom:'250px'}}>

                            <form class="form w-100" novalidate="novalidate" id="kt_sign_in_form" data-kt-redirect-url="../../demo29/dist/index.html" action="#">

                                <div class="card-body">

                                    <div class="text-start mb-10">

                                        <h1 class="text-dark mb-3 fs-3x" data-kt-translate="sign-in-title">Se connecter</h1>
                                    </div>

                                    <div class="fv-row mb-8">

                                        <input type="text" placeholder="Email" name="email" autocomplete="off" data-kt-translate="sign-in-input-email" class="form-control form-control-solid" />

                                    </div>

                                    <div class="fv-row mb-7">

                                        <input type="password" placeholder="Mot de passe" name="password" autocomplete="off" data-kt-translate="sign-in-input-password" class="form-control form-control-solid" />

                                    </div>


                                    <div class="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-10">
                                        <div></div>

                                        <a href="#" class="" data-kt-translate="sign-in-forgot-password" style={{color:'#8e736a'}}>Mot de passe oublié ?</a>

                                    </div>

                                    <div class="d-flex flex-stack">
                                        <Link to='/home'>
                                      

                                        <button id="kt_sign_in_submit" class="btn me-2 flex-shrink-0" style={{backgroundColor:'#8e736a'}}>

                                            <span class="indicator-label" data-kt-translate="sign-in-submit" style={{color:'white'}}>S'identifier</span>


                                            <span class="indicator-progress">
                                                <span data-kt-translate="general-progress">Please wait...</span>
                                                <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                                            </span>

                                        </button>

                                        </Link>

                                        {/* <div class="d-flex align-items-center">
                                            <div class="text-gray-400 fw-semibold fs-6 me-3 me-md-6" data-kt-translate="general-or">Or</div>

                                            <a href="#" class="symbol symbol-circle symbol-45px w-45px bg-light me-3">
                                                <img alt="Logo" src="assets/media/svg/brand-logos/google-icon.svg" class="p-4" />
                                            </a>

                                            <a href="#" class="symbol symbol-circle symbol-45px w-45px bg-light me-3">
                                                <img alt="Logo" src="assets/media/svg/brand-logos/facebook-3.svg" class="p-4" />
                                            </a>

                                            <a href="#" class="symbol symbol-circle symbol-45px w-45px bg-light">
                                                <img alt="Logo" src="assets/media/svg/brand-logos/apple-black.svg" class="theme-light-show p-4" />
                                                <img alt="Logo" src="assets/media/svg/brand-logos/apple-black-dark.svg" class="theme-dark-show p-4" />
                                            </a>

                                        </div> */}

                                    </div>

                                </div>

                            </form>

                        </div>

                        {/* <div class="m-0">

                            <button class="btn btn-flex btn-link rotate" data-kt-menu-trigger="click" data-kt-menu-placement="bottom-start" data-kt-menu-offset="0px, 0px">
                                <img data-kt-element="current-lang-flag" class="w-25px h-25px rounded-circle me-3" src="assets/media/flags/united-states.svg" alt="" />
                                <span data-kt-element="current-lang-name" class="me-2">English</span>
                                <i class="ki-outline ki-down fs-2 text-muted rotate-180 m-0"></i>
                            </button>

                            <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px py-4" data-kt-menu="true" id="kt_auth_lang_menu">

                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link d-flex px-5" data-kt-lang="English">
                                        <span class="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" class="rounded-1" src="assets/media/flags/united-states.svg" alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">English</span>
                                    </a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link d-flex px-5" data-kt-lang="Spanish">
                                        <span class="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" class="rounded-1" src="assets/media/flags/spain.svg" alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">Spanish</span>
                                    </a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link d-flex px-5" data-kt-lang="German">
                                        <span class="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" class="rounded-1" src="assets/media/flags/germany.svg" alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">German</span>
                                    </a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link d-flex px-5" data-kt-lang="Japanese">
                                        <span class="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" class="rounded-1" src="assets/media/flags/japan.svg" alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">Japanese</span>
                                    </a>
                                </div>


                                <div class="menu-item px-3">
                                    <a href="#" class="menu-link d-flex px-5" data-kt-lang="French">
                                        <span class="symbol symbol-20px me-4">
                                            <img data-kt-element="lang-flag" class="rounded-1" src="assets/media/flags/france.svg" alt="" />
                                        </span>
                                        <span data-kt-element="lang-name">French</span>
                                    </a>
                                </div>

                            </div>

                        </div> */}

                    </div>

                </div>

                <div class="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat" style={{ backgroundImage: `url(${Background})` }}></div>

            </div>

        </div>
        </div>
    )
};

export default SeConnecter;